import {useCallback, useEffect, useReducer} from 'react';
import _ from 'lodash';
import errorHandler from '../../util/errorHandler';
import {getVariables, updateVariable} from './endpoints.js';

const LOADED_DATA = 'LOADED_DATA';
const LOADING_DATA = 'LOADING_DATA';

export const initialState = {
    loading: null,
    variables: [],
};

export function reducer(state, action) {
    switch (action.type) {
        case LOADING_DATA:
            return {
                ...state,
                loading: _.get(state, 'loading', 0) + 1,
            };

        case LOADED_DATA: {
            let variables = action.response;
            _.forEach(variables, (variable) => {
                variable.value = variable.value.replace('.', ',');
            });
            return {
                ...state,
                variables: variables,
                loading: _.get(state, 'loading', 0) - 1,
            };
        }

        default:
            return state;
    }
}

function loadingVariables() {
    return {type: LOADING_DATA};
}

function loadedVariables(response) {
    return {type: LOADED_DATA, response};
}

export function loadVariables(dispatch, t) {
    dispatch(loadingVariables());

    getVariables()
        .then((response) => dispatch(loadedVariables(response.data)))
        .catch((e) => errorHandler(e.response.data, true, t));
}

function saveVariable(variable) {
    if (variable.type === 'Currency') {
        let newVariable = Object.assign({}, variable);
        newVariable.value = newVariable.value.replace(',', '.');
        return updateVariable(newVariable);
    }
    return updateVariable(variable);
}

function useVariables({t}) {
    const [state, dispatch] = useReducer(reducer, {...initialState});

    useEffect(() => {
        loadVariables(dispatch, t);
    }, []);

    const variableUpdate = useCallback((variable) => saveVariable(variable), []);

    return {
        ...state,
        variableUpdate,
        reload: () => loadVariables(dispatch, state),
    };
}

export default useVariables;
