import {t} from 'i18next';
import * as React from 'react';
import styled from 'styled-components';
import Table from '../../../shared/elements/table/TableFeature';
import VehicleJourneyEntries from './VehicleJourneyEntries';

export default function RevenueTable({selectedVehicle}) {
    if (!selectedVehicle)
        return (
            <RevenueTableContainer>
                <p>{t('vehicle.NoVehicleChosen')}</p>
            </RevenueTableContainer>
        );

    return (
        <RevenueTableContainer>
            <Table>
                <thead>
                    <Table.Row>
                        <Table.Header>
                            <b>{t('vehicleRevenue.OrderKey')}</b>
                        </Table.Header>
                        <Table.Header>
                            <b>{t('vehicleRevenue.RelationName')}</b>
                        </Table.Header>
                        <Table.Header>
                            <b>{t('vehicleRevenue.FirstTaskPlace')}</b>
                        </Table.Header>
                        <Table.Header>
                            <b>{t('vehicleRevenue.FirstTaskPostalCode')}</b>
                        </Table.Header>
                        <Table.Header>
                            <b>{t('vehicleRevenue.FirstTaskTimestamp')}</b>
                        </Table.Header>
                        <Table.Header>
                            <b>{t('vehicleRevenue.LastTaskTimestamp')}</b>
                        </Table.Header>
                        <Table.Header>
                            <b>{t('vehicleRevenue.LastTaskPlace')}</b>
                        </Table.Header>
                        <Table.Header>
                            <b>{t('vehicleRevenue.LastTaskPostalCode')}</b>
                        </Table.Header>
                        <Table.Header>
                            <b>{t('vehicleRevenue.NumberOfTasks')}</b>
                        </Table.Header>
                        <Table.Header>
                            <b>{t('vehicleRevenue.PurchaseCost')}</b>
                        </Table.Header>
                        <Table.Header>
                            <b>{t('vehicleRevenue.PurchaseCostExclBTW')}</b>
                        </Table.Header>
                        <Table.Header>
                            <b>{t('vehicleRevenue.PurchaseCostExclBTWLeft')}</b>
                        </Table.Header>
                    </Table.Row>
                </thead>

                <tbody>
                    {_.map(
                        selectedVehicle.totalPurchaseCost.sort((a, b) => a.month > b.month),
                        (pc) => {
                            var vehicleJourneys = _.filter(
                                selectedVehicle.vehicleJourneyEntries,
                                (el) => el.month === pc.month,
                            );

                            return vehicleJourneys.length !== 0 ? (
                                <VehicleJourneyEntries journeys={vehicleJourneys} totalCost={pc.costExclBTW} />
                            ) : null;
                        },
                    )}
                </tbody>
            </Table>
        </RevenueTableContainer>
    );
}

export const RevenueTableContainer = styled.div`
    width: 100%;
`;
