import {Formik, Field, Form, ErrorMessage} from 'formik';
import React from 'react';
import * as Yup from 'yup';
import {Modal} from '../../shared/modal/Modal';
import {Btn, CancelBtn} from '../../shared/elements/btn/Btn.js';
import {FormInput} from '../../shared/form/form-control/FormInput.js';
import {Row} from '../../shared/grid/grid-features/Row';
import {Col} from '../../shared/grid/grid-features/Col';

import {ValidationErrorTxt} from '../../shared/form/form-features/ValidationErrorTxt';
import {withTranslation} from 'react-i18next';

function UserAddModal({cancel, save, t}) {
    const validationSchema = Yup.object().shape({
        firstName: Yup.string().required(t('errors.RequiredField')),
        lastName: Yup.string().required(t('errors.RequiredField')),
        role: Yup.string().required(t('errors.RequiredField')),
        email: Yup.string().email(t('errors.InvalidEmail')).required(t('errors.RequiredField')),
    });

    return (
        <div>
            <Formik
                onSubmit={save}
                validationSchema={validationSchema}
                initialValues={{firstName: '', lastName: '', email: '', role: ''}}
            >
                {({isSubmitting, handleSubmit}) => (
                    <Form>
                        <Modal title={t('user.AddUser')}>
                            <Modal.Body>
                                <div role="group" aria-labelledby="my-radio-group">
                                    <label>
                                        <Field type="radio" name="role" value="Admin" />
                                        {t('roles.Admin')}
                                    </label>
                                    <label>
                                        <Field type="radio" name="role" value="User" />
                                        {t('roles.User')}
                                    </label>
                                </div>
                                <ErrorMessage name="role">
                                    {(msg) => <ValidationErrorTxt>{msg}</ValidationErrorTxt>}
                                </ErrorMessage>
                                <br></br>
                                <Row>
                                    <Col colsize="50">
                                        <FormInput name="firstName" placeholder={t('user.FirstName')} />
                                    </Col>
                                    <Col colsize="50">
                                        <FormInput name="lastName" placeholder={t('user.LastName')} />
                                    </Col>
                                </Row>
                                <FormInput name="email" placeholder={t('user.Email')} />
                            </Modal.Body>
                            <Modal.Footer>
                                <CancelBtn type="button" color="default" onClick={cancel} disabled={isSubmitting}>
                                    {t('general.Cancel')}
                                </CancelBtn>
                                <Btn onClick={handleSubmit} color="success" disabled={isSubmitting}>
                                    {t('general.Submit')}
                                </Btn>
                            </Modal.Footer>
                        </Modal>
                    </Form>
                )}
            </Formik>
        </div>
    );
}

export default withTranslation()(UserAddModal);
