export const scrollSetup = () => `
    &::-webkit-scrollbar {
        background: #f5f5f5;
        width: 8px;
        height: 8px;
        border-radius: 50px; 
        padding-right: 2px;
    }

    &::-webkit-scrollbar-track {
        background: #f5f5f5;
    }
   &::-webkit-scrollbar-thumb {
        border-radius: 10px;
        -webkit-box-shadow: inset 1px 1px 6px 1px rgba(38, 38, 38, 0.16);
        background: white;
        cursor: pointer;
    }

`;
