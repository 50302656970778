import {createGlobalStyle} from 'styled-components';

const BodyContainer = createGlobalStyle`
    body {
        font-family: 'Montserrat';
        margin: 0;
        color: #2c2c2c;
    }
    h1{
        font-size: 20px;
        margin: 0;
        margin-bottom: 4px;
    }
    i{
        font-size: 14px;
        margin-bottom: 2px;
        display: inline-block;
        color: var(--default-sub-txt);
    }

    h2{
        font-size: 18px;
        margin: 0;
        margin-bottom: 4px;
    }
    h3{
        font-size: 16px;
        margin: 0;
        margin-bottom: 4px;
    }
    p{
        margin: 0;
        margin-bottom: 1rem;
        color: var(--default-sub-txt);
    }
    *,
    *::before,
    *::after {
        box-sizing: border-box; // 1
    }
`;

export default BodyContainer;
