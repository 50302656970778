import React, {useState} from 'react';
import {FormFile} from '../../../shared/form/form-control/FormFile.js';
import {LineSpacer} from '../../../shared/custom-features/spacer/LineSpacer.js';
import {Btn} from '../../../shared/elements/btn/Btn';
import {MainContent} from '../../../shared/grid/main-content/MainContentFeatures.js';
import {Card} from '../../../shared/elements/card/Card.js';
import {downloadResultsOverview as downloadResultsOverviewRequest} from '../endpoints.js';
import {saveAs} from 'file-saver';
import errorHandler from '../../../util/errorHandler.js';
import {withTranslation} from 'react-i18next';
import {StackedSpacer} from '../../../shared/custom-features/spacer/StackedSpacer.js';

function VehicleResultsOverview({t}) {
    const [resultsFile, setResultsFile] = useState(null);
    const [loading, setLoading] = useState(false);

    const onRLUpload = (event) => {
        setResultsFile(event.target.files[0]);
    };

    const downloadResultsOverview = () => {
        setLoading(true);

        downloadResultsOverviewRequest(resultsFile)
            .then((res) => {
                let extension = 'zip';
                let tempFileName = `Resultaat overzicht ${resultsFile.name.replace('.xlsx', '')}`;
                let fileName = `${tempFileName}.${extension}`;

                const blob = new Blob([res.data], {
                    type: 'application/octet-stream',
                });
                saveAs(blob, fileName);
            })
            .catch((e) => {
                errorHandler(e.response.data, true, t);
            })
            .finally(() => {
                setLoading(false);
            });
    };

    return (
        <>
            <MainContent.Body>
                <StackedSpacer space="md">
                    <Card color="primary">
                        <h3>{t('explanations.UploadResultsFile')}</h3>
                        <ol>
                            <li>{t('explanations.NeedsExcel')}</li>
                        </ol>
                    </Card>
                    <LineSpacer space="sm">
                        {!loading && (
                            <FormFile
                                label={t('resultsOverview.UploadResultsFile')}
                                accept=".xlsx"
                                onChange={onRLUpload}
                                fileName={resultsFile && resultsFile.name}
                            />
                        )}
                    </LineSpacer>
                    {resultsFile !== null && (
                        <Btn
                            type="button"
                            color="success"
                            onClick={downloadResultsOverview}
                            disabled={resultsFile == null || loading}
                        >
                            {loading ? t('general.Busy') : t('general.Generate')}
                        </Btn>
                    )}
                </StackedSpacer>
            </MainContent.Body>
        </>
    );
}

export default withTranslation()(VehicleResultsOverview);
