import {useState, useEffect} from 'react';
import {getOvertimes, updateOvertimes} from './endpoints.js';
import errorHandler from '../../util/errorHandler.js';
import moment from 'moment';
import 'moment/locale/nl';
moment.locale('nl');

export default function useOvertimes(weekStart) {
    const [overtimes, setOvertimes] = useState(null);

    useEffect(() => {
        setOvertimes(null);
        getOvertimes({weekStart: weekStart})
            .then((response) => setOvertimes(response.data))
            .catch((e) => errorHandler(e.response.data, true));
    }, [weekStart]);

    return {
        overtimes,
        loading: overtimes == null,
        update: (overtimes) => updateOvertimes(overtimes),
    };
}
