import React from 'react';
import styled from 'styled-components';
import {NavLink} from 'react-router-dom';

const TopbarContainer = styled.div`
    background: var(--alpha);
    padding: 20px var(--space-lg);
    display: flex;
    justify-content: space-between;
    align-items: center;
    height: 80px;

    position: fixed;
    width: 100%;
    z-index: 3;
    img {
        height: 40px;
        margin-right: 20px;
    }
`;

const TopbarNav = styled.div`
    display: flex;
    align-items: center;
    .active {
        color: var(--warning);
        opacity: 1;
    }
`;
const TopbarOption = styled(NavLink)`
    margin-right: 12px;
    font-size: 14px;
    text-decoration: none;
    // color: var(--delta);
    color: white;
    opacity: 0.6;
    transition: 0.7s;
    &:hover {
        color: var(--delta);
        opacity: 1;
    }
`;

const Topbar = ({children}) => <TopbarContainer>{children}</TopbarContainer>;

export {Topbar, TopbarContainer, TopbarNav, TopbarOption};
Topbar.Nav = TopbarNav;
Topbar.Option = TopbarOption;
