import React from 'react';
import {useTranslation} from 'react-i18next';
import EN from '../../img/languages/flag-style-england.png';
import NL from '../../img/languages/flag-stlye-nederlands.png';
import styled from 'styled-components';

function TranslationSwitch() {
    var {i18n} = useTranslation();

    return (
        <Wrapper>
            <img
                src={NL}
                alt="nl"
                onClick={() => i18n.changeLanguage('nl')}
                style={
                    i18n.language === 'nl'
                        ? {border: '2px solid var(--delta)', width: '20px', height: '20px', borderRadius: '50%'}
                        : undefined
                }
            />
            {'  '}
            <img
                src={EN}
                alt="en"
                onClick={() => i18n.changeLanguage('en')}
                style={
                    i18n.language === 'en'
                        ? {border: '2px solid var(--delta)', width: '20px', height: '20px', borderRadius: '50%'}
                        : undefined
                }
            />
        </Wrapper>
    );
}
const Wrapper = styled.div`
    margin-left: auto;
    margin-right: 0;
    img {
        width: 20px !important;
        height: 20px !important;
        margin-right: 4px !important;
    }
`;

export default TranslationSwitch;
