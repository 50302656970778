import styled from 'styled-components';

const LoginBox = styled.div`
    --login-section: 400px;
    --login-section-last: var(--login-section);
    --login-section-desplay: flex;

    @media only screen and (max-width: 900px) {
        --login-section: 400px;
        --login-section-last: 0;
        --login-section-desplay: block;
    }

    max-width: 900px;
    width: 100%;
    margin: auto;
    display: var(--login-section-desplay);
    align-items: center;
    z-index: 2;
    position: relative;
`;

const LoginBoxContent = styled.div`
    padding: var(--space-lg);
    width: var(--login-section);
    &:last-of-type {
        border: none;
        width: calc(100% - var(--login-section-last));
        @media only screen and (min-width: 900px) {
            border-left: 1px solid #eaecf0;
        }
    }
`;

const LoginHeader = styled.div`
    h1 {
        font-size: 18px;
        color: white;
    }
    h2 {
        font-size: 14px;
        color: white;
        opacity: 0.7;
        font-weight: 400;
        margin-bottom: var(--space-lg);
    }
`;

const LoginBody = styled.div``;

export {LoginBox, LoginBody, LoginHeader, LoginBoxContent};
LoginBox.Body = LoginBody;
LoginBox.Header = LoginHeader;
LoginBox.Content = LoginBoxContent;
