import React from 'react';
import styled from 'styled-components';
import {ContentBodyContainer, MainContent} from './MainContent';
import {MainContentSidebar} from './MainContentSidebar';

const MainContentBody = ({title, children}) => (
    <ContentBodyContainer>
        {title && <h2>{title}</h2>}
        {children}
    </ContentBodyContainer>
);

export const MainContentHeader = styled.div`
    margin-bottom: var(--space-xl);
`;

export {MainContent, MainContentBody, MainContentSidebar};
MainContent.Body = MainContentBody;
MainContent.Header = MainContentHeader;
MainContent.Sidebar = MainContentSidebar;
