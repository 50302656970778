import i18n from 'i18next';
import {initReactI18next} from 'react-i18next';

import LanguageDetector from 'i18next-browser-languagedetector';

const resources = {
    nl: {
        translation: {
            login: {
                login: 'Inloggen',
                welcome: 'WELKOM BIJ DE CSE TOOL',
                credentials: 'Vul uw gegevens in om in te loggen.',
                passwordForgot: 'Wachtwoord vergeten?',
                loggedInAs: 'Ingelogd als',
                passwordReset: 'Wachtwoord herstel',
                passwordResetText: 'Voer hieronder uw nieuwe wachtwoord in',
                goBackToLogin: 'Ga terug naar het loginscherm.',
                submit: 'Opslaan',
                passwordSaved: 'Wachtwoord ingesteld.',
                passwordSavedText: 'Je nieuwe wachtwoord is succesvol ingesteld.',
                activateAccount: 'Account activeren',
                activateAccountText: 'Voer hieronder uw nieuwe wachtwoord in.',
                send: 'Verzenden',
                accountActivated: 'Account geactiveerd.',
                accountActivatedText: 'Bedankt voor het activeren van uw account. U kunt nu inloggen.',
                forgotPassword: 'Wachtwoord vergeten',
                forgotPasswordText:
                    'Voer hieronder uw e-mailadres in, en wij versturen u een mail met verdere instructies.',
                resendActivationMailSuccess: 'Activatiemail verzonden.',
                resendActivation: 'Verstuur activatiemail opnieuw',
                emailSend: 'Email is verstuurd',
                emailSendText: 'We hebben een mail gestuurd met verdere instructies. U kunt dit scherm sluiten.',
                username: 'Gebruikersnaam',
                password: 'Wachtwoord',
                passwordConfirm: 'Wachtwoord bevestigen',
                chooseLanguage: 'Kies een taal',
                email: 'Email',
            },
            errors: {
                RoleNotFound: 'De rol kon niet gevonden worden. Dit is een server error',
                DuplicateUserName: 'Er bestaat al een gebruiker met dit e-mailadres',
                UserNotFound: 'Gebruiker kon niet gevonden worden',
                InvalidResetToken: 'Deze link is niet meer geldig',
                InvalidActivationToken: 'Deze link is niet meer geldig',
                WeekOrYearNotFound: 'De week of het jaar kan niet uit de bestandsnaam worden gelezen',
                NoCSVFormat: 'Het bestand moet in CSV formaat zijn',
                WrongDataFile: 'De data in het bestand heeft niet de verwachte structuur',
                AccountBlocked: 'Uw account is geblokkeerd',
                IncorrectPassword: 'Het wachtwoord is incorrect',
                PasswordRequires: 'Het wachtwoord moet <> bevatten',
                PasswordRequiresNonAlphanumeric: 'een bijzonder teken',
                PasswordRequiresDigit: 'een cijfer',
                PasswordRequiresUpper: 'een hoofdletter',
                PasswordRequiresLower: 'een kleine letter',
                VehicleNotFound: 'Voertuig kon niet gevonden worden',
                VehicleAddFailed: 'Voertuig kon niet toegevoegd worden',
                LicensePlateAlreadyExists: 'Er bestaat al een voertuig met dit kenteken',
                LicensePlateOrMendrixIdAlreadyExists: 'Er bestaat al een voertuig met dit kenteken of dit Mendrix Id',
                MendrixIdAlreadyExists: 'Er bestaat al een voertuig met dit Mendrix Id',
                VehicleDeleteFailed: 'Voertuig kon niet verwijderd worden',
                ServerError: 'Er is iets fout gegaan, neem contact op met support.',
                SomethingWrong: 'Er is iets fout gegaan',
                UnknownUserType: 'Gebruikers rol onbekend',
                RequiredField: 'Verplicht veld',
                InvalidEmail: 'Ongeldig e-mailadres',
                NonidenticalPassword: 'Wachtwoorden komen niet overeen',
                NoWeekstaten: 'Weekstaten van deze week zijn nog niet gegenereerd!',
                NoRevenue: 'Omzetten van deze week zijn nog niet geüpload!',
                NoWeekstatenAndRevenue: 'Weekstaten and omzetten van deze week zijn nog niet gegenereerd/geüpload!',
                MailIsConfirmed: 'Email is al bevestigd',
            },
            explanations: {
                NeedsCSVAndWeekYear:
                    'Het bestand moet csv formaat hebben en de naam moet een weeknummer en een jaartal bevatten als volgt: "week 1-2022".',
                NeedsExcel:
                    'Het bestand moet excel formaat hebben en de naam moet een weeknummer en een jaartal bevatten als volgt: "week 1-2022".',
                NeedsUTFEncoding: 'Het bestand moet in de UTF-8 encodering opgeslagen zijn.',
                UploadRittenlijst: 'Je dient hieronder de "rittenlijsten" te uploaden',
                UploadOmzetlijst: 'Je dient hieronder het "omzetbestand" te uploaden',
                UploadResultsFile: 'Je dient hieronder het "resultaat-bestand" te uploaden',
                ChooseTheWeek: 'Kies de week',
                ValidationErrors:
                    'Er zijn één of meer voertuigen met ongeldige tijdstippen. Deze voertuigen zullen niet worden opgeslagen',
            },
            roles: {
                Admin: 'Beheerder',
                User: 'Gebruiker',
            },
            statuses: {
                Pending: 'In afwachting',
                Active: 'Actief',
                Blocked: 'Geblokkeerd',
            },
            general: {
                MendriX: 'MendriX',
                VariableOverview: 'Overzicht variabelen',
                Revenue: 'Omzet',
                VehicleRevenues: 'Overzicht omzetgegevens voertuigen',
                Comparison: 'Vergelijken',
                ComparisonTitle: 'Kilometerstaten met Rittenlijsten vergelijken',
                Normalize: 'Normaliseren',
                CseTool: 'CSE Tool',
                Loading: 'Aan het laden...',
                Save: 'Opslaan',
                Variables: 'Variabelen',
                Cancel: 'Annuleren',
                Submit: 'Opslaan',
                Confirm: 'Bevestigen',
                Users: 'Gebruikers',
                Weekstaten: 'Weekstaten',
                Vehicles: 'Voertuigen',
                VehicleOvertimes: 'Weekend uren',
                StationaryTool: 'Stilstand',
                ChangesSaved: 'Wijzigingen opgeslagen',
                ChangesNotSaved: 'Wijzigingen konden niet worden opgeslagen',
                Till: 'Tot',
                Saturday: 'Zaterdag',
                Sunday: 'Zondag',
                Holidays: 'Feestdagen',
                NotesAdmin: 'Notities NL',
                NotesUser: 'Notities PL',
                Total: 'Totaal',
                SelectWeek: 'Selecteer week',
                Search: 'Zoeken',
                Busy: 'Bezig...',
                Generate: 'Genereren',
                And: 'En',
                Page: 'Pagina',
                Of: 'van',
            },
            variables: {
                Variable: 'Variabele',
                Value: 'Waarde',
                Type: 'Type',
                NoVariablesFound: 'Geen variabelen gevonden',
                types: {
                    Currency: 'Bedrag',
                },
                names: {
                    DrivenDayTariffTruck: 'Gereden dagtarief truck',
                    DrivenDayTariffCombi: 'Gereden dagtarief combi',
                    NonDrivenDayTariffTruck: 'Niet-gereden dagtarief truck',
                    NonDrivenDayTariffCombi: 'Niet-gereden dagtarief combi',
                    WeekendHoursTariff: 'Weekendurentarief',
                },
            },
            user: {
                AddUser: 'Gebruiker toevoegen',
                EditUser: 'Gebruiker wijzigen',
                UserOverview: 'Gebruikersoverzicht',
                FirstName: 'Voornaam',
                LastName: 'Achternaam',
                FullName: 'Volledige naam',
                Role: 'Rol',
                Status: 'Status',
                NoUsersFound: 'Geen gebruikers gevonden',
                Email: 'Email',
            },
            vehicle: {
                Vehicle: 'Voertuig',
                Charter: 'Charter',
                Dutch: 'Dutch',
                Polish: 'Polish',
                AddVehicle: 'Voertuig toevoegen',
                EditVehicle: 'Voertuig bewerken',
                VehicleOverview: 'Voertuigen overzicht',
                Number: 'Voertuignummer',
                LicensePlate: 'Kenteken',
                Type: 'Type',
                NoVehiclesFound: 'Geen voertuigen gevonden',
                ConfirmDelete: 'Weet je zeker dat je dit voertuig wilt verwijderen?',
                NoVehicleChosen: 'Geen voertuig gekozen',
                VolumeType: 'Volumetype',
                Truck: 'Truck',
                Combi: 'Combi',
                ExemptedGermanToll: 'Vrijstelling van Duitse tol',
            },
            weekstaten: {
                GenerateWeekstaten: 'Weekstaten genereren',
                UploadRittenlijst: 'Upload rittenlijsten',
            },
            resultsOverview: {
                GenerateResultsOverview: 'Genereer resultaat overzicht',
                GenerateSummaryPerWeek: 'Genereer samenvatting per week',
                UploadResultsFile: 'Upload resultaat bestand',
            },
            kilometerstaten: {
                NormalizeKS: 'Normaliseer kilometerstaten',
                UploadOmzetlijst: 'Upload omzetlijst',
            },
            vehicleRevenue: {
                UploadOmzetlijst: 'Upload omzetlijst',
                GenerateTurnover: 'Omzetgegevens voertuigen genereren',
                OrderKey: 'Order #',
                RelationName: 'Naam relatie',
                FirstTaskPlace: 'Plaats, eerste taak',
                FirstTaskPostalCode: 'Postcode, eerste taak',
                FirstTaskTimestamp: 'Gepland, eerste taak',
                LastTaskTimestamp: 'Gepland, laatste taak',
                LastTaskPlace: 'Plaats, laatste taak',
                LastTaskPostalCode: 'Postcode, laatste taak',
                NumberOfTasks: 'Takenaantal',
                PurchaseCost: 'Inkoopbedrag',
                PurchaseCostExclBTW: 'Excl. BTW',
                PurchaseCostExclBTWLeft: 'Over',
                Total: 'totaal',
            },
            stilstand: {
                Number: 'Voertuignummer',
                LicensePlate: 'Kenteken',
                Monday: 'Maandag',
                Tuesday: 'Dinsdag',
                Wednesday: 'Woensdag',
                Thursday: 'Donderdag',
                Friday: 'Vrijdag',
                Saturday: 'Zaterdag',
                Sunday: 'Zondag',
                SelectAVehicle: 'Selecteer een voertuig',
                SpecialNotes: 'Extra / speciale opmerkingen',
                SelectStandartReason: 'Selecteer standaard reden',
            },
            mendrix: {
                RetrieveDataShort: 'Haal gegevens op',
                RetrieveData: 'Haal alle relevante informatie op uit MendriX',
                CheckStatus: 'Check MendriX-status',
                TerminateRetrieval: 'Stop ophalen van gegevens',
                SomethingWentWrong: 'Er is iets fout gegaan',
                CancelledByUser: 'Geannuleerd door gebruiker',
                Failed: 'Mislukt',
                Starting: 'Begint nu',
                Finished: 'Klaar',
            },
        },
    },
    en: {
        translation: {
            login: {
                login: 'Login',
                welcome: 'WELCOME TO THE CSE TOOL',
                credentials: 'Please fill in your credentials to login.',
                passwordForgot: 'Forgot your password?',
                loggedInAs: 'Logged in as',
                passwordReset: 'Password reset',
                passwordResetText: 'Fill in your password',
                goBackToLogin: 'Go back to login screen',
                submit: 'Save',
                passwordSaved: 'Password saved',
                passwordSavedText: 'Your new password is saved.',
                activateAccount: 'Activate account',
                activateAccountText: 'Fill in your new password.',
                send: 'Send',
                accountActivated: 'Account activated',
                accountActivatedText: 'Thank you for activating your account, you can login now',
                forgotPassword: 'Forgot password',
                forgotPasswordText: 'Fill in your email addres, we will send you an email with further instructions.',
                resendActivation: 'Resend activation mail',
                resendActivationMailSucces: 'Activation mail sent',
                emailSend: 'Email is send',
                emailSendText: 'We have send you an email with further instructions you can close this screen now.',
                username: 'Username',
                password: 'Password',
                passwordConfirm: 'Password confirm',
                chooseLanguage: 'Choose a language',
                email: 'Email',
            },
            errors: {
                RoleNotFound: 'The role could not be found. This is a server error.',
                DuplicateUserName: 'User already exists with this e-mail',
                UserNotFound: 'User could not be found',
                InvalidResetToken: 'This link is not valid anymore',
                InvalidActivationToken: 'The activation link is not valid anymore',
                WeekOrYearNotFound: 'Invalid file name, the week or year could not be read from the filename.',
                NoCSVFormat: 'The file needs to be in CSV format',
                WrongDataFile: 'The data in the file does not have the required structure',
                AccountBlocked: 'Your account is blocked',
                IncorrectPassword: 'Password is incorrect',
                PasswordRequires: 'Password requires <>',
                PasswordRequiresNonAlphanumeric: 'a special character',
                PasswordRequiresDigit: 'a number',
                PasswordRequiresUpper: 'a capital letter',
                PasswordRequiresLower: 'a small letter',
                VehicleNotFound: 'Vehicle could not be found.',
                VehicleAddFailed: 'Vehicle could not be added.',
                LicensePlateAlreadyExists: 'A vehicle with this license plate already exists',
                LicensePlateOrMendrixIdAlreadyExists:
                    'A vehicle with this license plate or this Mendrix Id already exists',
                MendrixIdAlreadyExists: 'A vehicle with this Mendrix Id already exists',
                VehicleDeleteFailed: 'Vehicle could not be deleted',
                ServerError: 'Something went wrong, please contact support',
                SomethingWrong: 'Something went wrong',
                UnknownUserType: 'Unknown user type',
                RequiredField: 'Required field',
                InvalidEmail: 'Invalid email',
                NonidenticalPassword: 'Passwords do not match',
                NoWeekstaten: 'Weekstaten for this week have not been generated yet!',
                NoRevenue: 'Revenues for this week have not been uploaded yet!',
                NoWeekstatenAndRevenue: 'Weekstaten and revenues for this week have not been generated/uploaded yet!',
                MailIsConfirmed: 'Email address is already confirmed',
            },
            explanations: {
                MendriX: 'Retrieve all the necessary information from MendriX',
                NeedsCSVAndWeekYear:
                    'The file needs to be in CSV format and needs to contain a week number and year like follows: "week 1-2022".',
                NeedsExcel:
                    'The file needs to be in Excel format and needs to contain a week number and year like follows: "week 1-2022".',
                NeedsUTFEncoding: 'The file needs to be saved in UTF-8 encoding',
                UploadRittenlijst: 'Please upload the "rittenlijsten" below',
                UploadOmzetlijst: 'Please upload the "omzetbestand" below',
                UploadResultsFile: 'Please upload the "resultaat-bestand" below',
                ChooseTheWeek: 'Please choose the week',
                ValidationErrors:
                    'There are one or more vehicles with validation errors. These vehicles will not be saved',
            },
            roles: {
                Admin: 'Admin',
                User: 'User',
            },
            statuses: {
                Pending: 'Pending',
                Active: 'Active',
                Blocked: 'Blocked',
            },
            general: {
                MendriX: 'MendriX',
                VariableOverview: 'Variable overview',
                Revenue: 'Revenue',
                VehicleRevenues: 'Vehicle revenue overview',
                Comparison: 'Compare',
                ComparisonTitle: 'Kilometerstaten with Rittenlijsten comparison',
                Normalize: 'Normalize',
                CseTool: 'CSE Tool',
                Loading: 'Loading...',
                Save: 'Save',
                Variables: 'Variables',
                Cancel: 'Cancel',
                Submit: 'Submit',
                Confirm: 'Confirm',
                Users: 'Users',
                Weekstaten: 'Weekstaten',
                Vehicles: 'Vehicles',
                VehicleOvertimes: 'Weekend hours',
                StationaryTool: 'Stationary',
                ChangesSaved: 'Changes saved',
                ChangesNotSaved: 'Changes could not be saved',
                Till: 'Till',
                Saturday: 'Saturday',
                Sunday: 'Sunday',
                Holidays: 'Holidays',
                NotesAdmin: 'Notes NL',
                NotesUser: 'Notes PL',
                Total: 'Total',
                SelectWeek: 'Select week',
                Search: 'Search',
                Busy: 'Busy...',
                Generate: 'Generate',
                And: 'And',
                Page: 'Page',
                Of: 'of',
            },
            variables: {
                Variable: 'Variabele',
                Value: 'Value',
                Type: 'Type',
                NoVariablesFound: 'No variabele found',
                types: {
                    Currency: 'Currency',
                },
                names: {
                    DrivenDayTariffTruck: 'Driven day tariff truck',
                    DrivenDayTariffCombi: 'Driven day tariff combi',
                    NonDrivenDayTariffTruck: 'Non driven day tariff truck',
                    NonDrivenDayTariffCombi: 'Non driven day tariff combi',
                    WeekendHoursTariff: 'Weekend hours tariff',
                },
            },
            user: {
                AddUser: 'Add user',
                EditUser: 'Edit user',
                UserOverview: 'User overview',
                FirstName: 'Firstname',
                LastName: 'Surname',
                FullName: 'Fullname',
                Role: 'Role',
                Status: 'Status',
                NoUsersFound: 'No users found',
                Email: 'Email',
            },
            vehicle: {
                Vehicle: 'Vehicle',
                Charter: 'Charter',
                Dutch: 'Dutch',
                Polish: 'Polish',
                AddVehicle: 'Add vehicle',
                EditVehicle: 'Edit vehicle',
                VehicleOverview: 'Vehicle overview',
                Number: 'Vehicle number',
                LicensePlate: 'License plate',
                Type: 'Type',
                NoVehiclesFound: 'No vehicles found',
                ConfirmDelete: 'Are you sure you want to delete this vehicle?',
                NoVehicleChosen: 'No vehicle chosen',
                VolumeType: 'Volume type',
                Truck: 'Truck',
                Combi: 'Combi',
                ExemptedGermanToll: 'Exempted of German toll',
            },
            weekstaten: {
                GenerateWeekstaten: 'Generate weekstaten',
                UploadRittenlijst: 'Upload rittenlijsten',
            },
            resultsOverview: {
                GenerateResultsOverview: 'Generate result overview',
                GenerateSummaryPerWeek: 'Generate summary per week',
                UploadResultsFile: 'Upload result file',
            },
            kilometerstaten: {
                UploadOmzetlijst: 'Upload omzetlijst',
                NormalizeKS: 'Normalize kilometerstaten',
            },
            vehicleRevenue: {
                UploadOmzetlijst: 'Upload "omzetlijst"',
                GenerateTurnover: 'Generate vehicle turnovers',
                OrderKey: 'Order #',
                RelationName: 'Relation name',
                FirstTaskPlace: 'Place, first task',
                FirstTaskPostalCode: 'Postalcode, first task',
                FirstTaskTimestamp: 'Timestamp, first task',
                LastTaskTimestamp: 'Timestamp, last task ',
                LastTaskPlace: 'Place, last task',
                LastTaskPostalCode: 'Postalcode, last task',
                NumberOfTasks: 'Number of tasks',
                PurchaseCost: 'Purchase cost',
                PurchaseCostExclBTW: 'Purchase cost excl. FAT',
                PurchaseCostExclBTWLeft: 'Left',
                Total: 'total',
            },
            stilstand: {
                Number: 'Vehicle number',
                LicensePlate: 'License plate',
                Monday: 'Monday',
                Tuesday: 'Tuesday',
                Wednesday: 'Wednesday',
                Thursday: 'Thursday',
                Friday: 'Friday',
                Saturday: 'Saturday',
                Sunday: 'Sunday',
                SelectAVehicle: 'Select a vehicle',
                SpecialNotes: 'Extra / special remarks',
                SelectStandartReason: 'Select standard reason',
            },
            mendrix: {
                RetrieveDataShort: 'Retrieve data',
                RetrieveData: 'Retrieve all the necessary information from MendriX',
                CheckStatus: 'Check MendriX status',
                TerminateRetrieval: 'Stop retrieval of data',
                SomethingWentWrong: 'Something went wrong',
                CancelledByUser: 'Cancelled by user',
                Failed: 'Failed',
                Starting: 'Starting',
                Finished: 'Finished',
            },
        },
    },
};

i18n.use(LanguageDetector)
    .use(initReactI18next)
    .init({
        resources,
        fallbackLng: 'en',

        interpolation: {
            escapeValue: false,
        },
        detection: {
            order: ['localStorage', 'navigator'],
            lookupLocalStorage: 'i18nextLng',
        },
    });

export default i18n;
