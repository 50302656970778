import React from 'react';
import styled from 'styled-components';

import {Field, ErrorMessage} from 'formik';
import {FormGroup} from '../form-features/FormGroup';
import {FormLabel} from '../form-features/FormLabel';
import {formElementSetup, insideFormElementSetup} from '../form-setup/formElementSetup';
import {ValidationErrorTxt} from '../form-features/ValidationErrorTxt';

export const FormInputContainer = styled.div`
    ${formElementSetup({
        height: 'unset',
    })};
    textarea {
        ${insideFormElementSetup({
            height: 'unset',
        })};
        padding-top: 6px;
        outline: none !important;
    }
    ${({disabled}) => {
        if (!disabled) return '';
        else
            return `
            opacity: 0.6;
            cursor: not-allowed;
            input{
                cursor: not-allowed;
            }
        `;
    }}
`;

const Wrapper = styled(FormGroup)``;

export const FormTextarea = function ({
    label,
    name,
    placeholder,
    type,
    disabled = false,
    borderError,
    prefixIcon,
    suffixIcon,
    size,
    rows,
}) {
    return (
        <Wrapper>
            {label && <FormLabel>{label}</FormLabel>}
            <FormInputContainer size={size} disabled={disabled} suffixIcon={suffixIcon} prefixIcon={prefixIcon}>
                <>
                    <Field
                        rows={rows}
                        as="textarea"
                        type={type}
                        id={name}
                        name={name}
                        placeholder={placeholder}
                        style={
                            borderError
                                ? {
                                      border: '1px solid red',
                                  }
                                : undefined
                        }
                    />

                    {!borderError && (
                        <ErrorMessage name={name}>
                            {(msg) => <ValidationErrorTxt>{msg}</ValidationErrorTxt>}
                        </ErrorMessage>
                    )}
                </>
            </FormInputContainer>
        </Wrapper>
    );
};
