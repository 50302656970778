import styled from 'styled-components';
import React from 'react';
import LoginBackgroundImg from '../../../img/trucks_ipaddown.jpg';
import {LoginBox} from './LoginFeatures';
import Logo from '../../../img/logo-cse.png';
import TranslationSwitch from '../../../modules/account/TranslationSwitch';
import {withTranslation} from 'react-i18next';

export const LoginGridContainer = styled.div`
    padding: var(--space-xl);
    position: relative;
    height: 100vh;
    display: flex;
    justify-content: center;
    background: var(--alpha);
    &:before {
        content: '';
        width: 100%;
        height: 100%;
        position: absolute;
        top: 0;
        top: 0;
        z-index: 2;
        background-image: linear-gradient(to right, var(--alpha), #162e601f, #162e601f, #162e601f);
    }
`;
export const LoginGridBackground = styled.img`
    position: absolute;
    top: 0;
    right: 0;
    height: 100%;
    width: 100%;
    z-index: 1;
    opacity: 0.05;
    pointer-evens: none;
`;

const LoginGrid = ({t, children}) => (
    <LoginGridContainer>
        <LoginGridBackground src={LoginBackgroundImg} alt="LogoImgLogin" />

        <LoginBox>
            <LoginBox.Content>
                <LogoWrapper>
                    <img src={Logo} alt="LogoImgLogin" />
                    <h2>Quality by difference</h2>
                </LogoWrapper>
                <TranslationWrapper>
                    <h2>{t('login.chooseLanguage')}</h2>
                    <TranslationSwitch />
                </TranslationWrapper>
            </LoginBox.Content>

            <LoginBox.Content>{children}</LoginBox.Content>
        </LoginBox>
    </LoginGridContainer>
);

export default withTranslation()(LoginGrid);

const TranslationWrapper = styled.div`
    padding-top: 50px;
    h2 {
        font-size: 15px;
        color: var(--primary-light);
        margin-bottom: var(--space-sm);
    }
`;

const LogoWrapper = styled.div`
    h2 {
        font-size: 28px;
        color: var(--delta);
        margin-bottom: var(--space-sm);
    }
    img {
        width: 120px;
        margin-bottom: var(--space-sm);
    }
`;
