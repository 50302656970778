export const formControlColors = () => `
    border: 1px solid var(--form-control-border-col);
    background: var(--form-control-background-col);

    input, select{
        height: 100% !important;
      
    }
    input, textarea, select {
        display: block;
        &:focus {
            box-shadow: 0 0 5px 0 #5d7dc0;
            background: #eff4ff!important;
        }
    }
`;

export const formElementSetup = ({height}) => `
    ${formControlColors()};
    height:  ${height || 'var(--form-control-height)'};
    border-radius: 2px;
    width: 100%;
    position: relative;
`;

export const inElementUi = () => `
    font-size: 1rem;
    color: var(--form-control-txt-col);
`;

export const insideFormElementSetup = ({height}) => `
    -webkit-appearance: none;
    -moz-appearance: none;
    background: none;
    border: none;
    overflow: hidden;
    height:  ${height || 'var(--form-control-height)'};
    width: 100%;
    padding: 0 var(--horz-input-padding);
    ${inElementUi()};
`;
