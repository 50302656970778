import {Formik, Form, Field} from 'formik';
import React from 'react';
import {FormInput} from '../../shared/form/form-control/FormInput.js';
import {Modal} from '../../shared/modal/Modal.js';
import {Btn, CancelBtn} from '../../shared/elements/btn/Btn.js';
import {Row} from '../../shared/grid/grid-features/Row';
import {Col} from '../../shared/grid/grid-features/Col';
import useVehicle from './VehicleState.js';
import errorHandler from '../../util/errorHandler.js';
import {withTranslation} from 'react-i18next';

function VehicleDeleteModal({licensePlate, close, reload, t}) {
    const {vehicle, loading, remove} = useVehicle(licensePlate, t);

    const deleteVehicle = () => {
        remove()
            .then(() => {
                reload();
                close();
            })
            .catch((e) => {
                errorHandler(e.response.data, true, t);
                reload();
                close();
            });
    };

    if (loading) return <span>{t('general.Loading')}</span>;

    return (
        <div>
            <Formik
                onSubmit={deleteVehicle}
                initialValues={{number: vehicle.number, licensePlate: vehicle.licensePlate, type: vehicle.type}}
            >
                {({isSubmitting, handleSubmit}) => (
                    <Form>
                        <Modal title={t('vehicle.ConfirmDelete')}>
                            <Modal.Body>
                                <div role="group" aria-labelledby="my-radio-group">
                                    <label>
                                        <Field type="radio" name="type" value="Charter" disabled={true} />
                                        {t('vehicle.Charter')}
                                    </label>
                                    <label>
                                        <Field type="radio" name="type" value="Dutch" disabled={true} />
                                        {t('vehicle.Dutch')}
                                    </label>
                                    <label>
                                        <Field type="radio" name="type" value="Polish" disabled={true} />
                                        {t('vehicle.Polish')}
                                    </label>
                                </div>
                                <br></br>
                                <Row>
                                    <Col colsize="50">
                                        <FormInput name="number" placeholder={t('vehicle.Number')} disabled={true} />
                                    </Col>
                                    <Col colsize="50">
                                        <FormInput
                                            name="licensePlate"
                                            placeholder={t('vehicle.LicensePlate')}
                                            disabled={true}
                                        />
                                    </Col>
                                </Row>
                                <Row>
                                    <Col colsize="50">
                                        <FormInput name="mendrixId" placeholder={'Mendrix Id'} disabled={true} />
                                    </Col>
                                </Row>
                            </Modal.Body>
                            <Modal.Footer>
                                <CancelBtn type="button" color="default" onClick={close} disabled={isSubmitting}>
                                    {t('general.Cancel')}
                                </CancelBtn>
                                <Btn onClick={handleSubmit} color="success" disabled={isSubmitting}>
                                    {t('general.Confirm')}
                                </Btn>
                            </Modal.Footer>
                        </Modal>
                    </Form>
                )}
            </Formik>
        </div>
    );
}

export default withTranslation()(VehicleDeleteModal);
