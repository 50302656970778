import axios from 'axios';

export function getStilstand(params) {
    return axios.get('/api/vehicles/stilstand', {params: params});
}

export function saveStilstand(stilstand) {
    return axios.post('/api/vehicles/stilstand', stilstand);
}

export function loadStandardReasons() {
    return axios.get('/api/vehicles/stilstand/reasons');
}
