import React, {useState, useEffect, useCallback} from 'react';
import {useFormikContext} from 'formik';
import _ from 'lodash';

const AutoSave = ({debounceMs = 1000}) => {
    const [count, setCount] = useState(0);
    const formik = useFormikContext();

    const debouncedSubmit = useCallback(
        _.debounce(() => {
            setCount(count + 1);

            // skips the form initialization trigger
            if (count == 0) return;

            return formik.submitForm();
        }, debounceMs),
        [count, setCount],
    );

    useEffect(() => {
        debouncedSubmit();
    }, [formik.values]);

    return <></>;
};

export default AutoSave;
