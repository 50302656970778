import axios from 'axios';

export function getUsers(params) {
    return axios.get('/api/users', {params: params});
}

export function getUserDetails(userId) {
    return axios.get(`/api/users/${userId}`).then((response) => {
        return response.data;
    });
}

export function addUser(user) {
    return axios.post('/api/users', user);
}

export function saveUserDetails(userId, details) {
    return axios.post(`/api/users/${userId}`, details);
}

export function uploadUsers(file) {
    var formData = new FormData();
    formData.append('file', file);

    return axios.post('/api/users/upload', formData);
}
