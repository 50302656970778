import React from 'react';
import styled from 'styled-components';

import {Field, ErrorMessage} from 'formik';
import {FormGroup} from '../form-features/FormGroup';
import {FormLabel} from '../form-features/FormLabel';
import {formElementSetup, insideFormElementSetup} from '../form-setup/formElementSetup';
import {ValidationErrorTxt} from '../form-features/ValidationErrorTxt';
import IconContainer, {Icon} from '../../elements/icon/Icon';

const PrefixBox = styled.div`
    background: white;
    height: calc(100% - 2px);
    width: 28px;
    position: absolute;
    left: 1px;
    top: 1px;
    border-right: 1px solid var(--form-control-border-col);
    pointer-events: none;
`;

export const FormInputContainer = styled.div`
    ${formElementSetup}
    input {
        ${insideFormElementSetup}
        outline: none!important;
    }
    ${({disabled}) => {
        if (!disabled) return '';
        else
            return `
            opacity: 0.6;
            cursor: not-allowed;
            input{
                cursor: not-allowed;
            }
        `;
    }}

    ${({size}) => {
        if (size === 'sm')
            return `
            height: 30px;
            input {
                font-size: 10px;
                padding: 0 6px;
                height: 30px;
            }
        `;
        if (size === 'xsm')
            return `
            height: 30px;
            width: 60px;
            input {
                font-size: 10px;
                padding: 0 6px;
                height: 30px;
            }
        `;
        else return '';
    }}

    ${({prefixIcon}) => {
        if (!prefixIcon) return '';
        else
            return `
            position: relative;
            input{
                padding-left: 36px!important;
            }
            ${IconContainer} {
                position: absolute;
                left: 50%;
                top: 50%;
                transform: translate(-50%, -50%);
                pointer-events: none;
                color: #999eaf;
                svg{
                    width: 14px;
                }
            }
        `;
    }}
    ${({suffixIcon}) => {
        if (!suffixIcon) return '';
        else
            return `
            position: relative;
            input{
                padding-right: 36px!important;
            }
            ${IconContainer} {
                position: absolute;
                left: 50%;
                top: 50%;
                transform: translate(-50%, -50%);
                pointer-events: none;
                color: #999eaf;
                svg{
                    width: 14px;
                }
            }
            ${PrefixBox} {
                left: unset!important;
                right: -1px;
            }
        `;
    }}
`;

const Wrapper = styled(FormGroup)``;

export const FormInput = function ({
    prefix,
    label,
    name,
    placeholder,
    type,
    disabled = false,
    borderError,
    prefixIcon,
    suffixIcon,
    size,
}) {
    return (
        <Wrapper>
            {label && <FormLabel>{label}</FormLabel>}
            <FormInputContainer size={size} disabled={disabled} suffixIcon={suffixIcon} prefixIcon={prefixIcon}>
                <>
                    <Field
                        prefix={prefix}
                        type={type}
                        name={name}
                        placeholder={placeholder}
                        disabled={disabled}
                        style={
                            borderError
                                ? {
                                      border: '1px solid red',
                                  }
                                : undefined
                        }
                    />
                    {!borderError && (
                        <ErrorMessage name={name}>
                            {(msg) => <ValidationErrorTxt>{msg}</ValidationErrorTxt>}
                        </ErrorMessage>
                    )}
                </>
                {prefixIcon && (
                    <PrefixBox>
                        <Icon icon={prefixIcon} />
                    </PrefixBox>
                )}
                {suffixIcon && (
                    <PrefixBox suffixIcon={suffixIcon}>
                        <Icon icon={suffixIcon} />
                    </PrefixBox>
                )}
            </FormInputContainer>
        </Wrapper>
    );
};
