import errorHandler from './errorHandler';
import _ from 'lodash';

export function transformModelErrors(errorsObj, t) {
    var transformedErrors = {};
    _.forOwn(errorsObj, (value, key) => {
        transformedErrors[_.camelCase(key)] = errorHandler(value[0], false, t);
    });

    return transformedErrors;
}

export function stringifyNulls(object) {
    if (!_.isObject(object)) return object;

    return _.mapValues(object, (value) => {
        if (_.isArray(value)) return _.map(value, (val) => stringifyNulls(val));
        if (_.isObject(value)) return stringifyNulls(value);
        else return _.isNull(value) ? '' : value;
    });
}
