import {Formik, Form, Field, ErrorMessage} from 'formik';
import React, {useEffect, useState} from 'react';
import * as Yup from 'yup';
import {Btn, CancelBtn} from '../../shared/elements/btn/Btn.js';
import {FormInput} from '../../shared/form/form-control/FormInput.js';
import {Modal} from '../../shared/modal/Modal.js';
import {Row} from '../../shared/grid/grid-features/Row';
import {Col} from '../../shared/grid/grid-features/Col';

import useUsers from './UserState.js';
import {withTranslation} from 'react-i18next';

function UserEditModal({userId, cancel, save, t}) {
    const {getUserDetail} = useUsers();
    const [user, setUser] = useState(null);

    useEffect(() => {
        getUserDetail(userId).then((user) => setUser(user));
    }, [userId]);

    const validationSchema = Yup.object().shape({
        firstName: Yup.string().required(t('errors.RequiredField')),
        lastName: Yup.string().required(t('errors.RequiredField')),
    });

    if (!user) return <span>{t('general.Loading')}</span>;

    return (
        <div>
            <Formik
                onSubmit={(values, options) => save(userId, values, options)}
                validationSchema={validationSchema}
                initialValues={{
                    firstName: user.firstName,
                    lastName: user.lastName,
                    role: user.role,
                    blocked: user.blocked,
                }}
            >
                {({isSubmitting, handleSubmit}) => (
                    <Form>
                        <Modal title={t('user.EditUser')}>
                            <Modal.Body>
                                <div role="group" aria-labelledby="my-radio-group">
                                    <label>
                                        <Field type="radio" name="role" value="Admin" />
                                        {t('roles.Admin')}
                                    </label>
                                    <label>
                                        <Field type="radio" name="role" value="User" />
                                        {t('roles.User')}
                                    </label>
                                    <ErrorMessage name="role">
                                        {(msg) => <span className="text-danger field-validation-error">{msg}</span>}
                                    </ErrorMessage>
                                </div>
                                <br></br>
                                <Row>
                                    <Col colsize="50">
                                        <FormInput name="firstName" placeholder={t('user.FirstName')} />
                                    </Col>
                                    <Col colsize="50">
                                        <FormInput name="lastName" placeholder={t('user.LastName')} />
                                    </Col>
                                </Row>
                                <div>
                                    <label>
                                        <Field type="checkbox" name="blocked" />
                                        {t('statuses.Blocked')}
                                    </label>
                                </div>
                            </Modal.Body>
                            <Modal.Footer>
                                <CancelBtn type="button" color="default" onClick={cancel} disabled={isSubmitting}>
                                    {t('general.Cancel')}
                                </CancelBtn>
                                <Btn onClick={handleSubmit} color="success" disabled={isSubmitting}>
                                    {t('general.Submit')}
                                </Btn>
                            </Modal.Footer>
                        </Modal>
                    </Form>
                )}
            </Formik>
        </div>
    );
}

export default withTranslation()(UserEditModal);
