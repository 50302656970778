import {Formik, Field, Form, ErrorMessage} from 'formik';
import React from 'react';
import * as Yup from 'yup';
import {Modal} from '../../shared/modal/Modal';
import {Btn, CancelBtn} from '../../shared/elements/btn/Btn.js';
import {FormInput} from '../../shared/form/form-control/FormInput.js';
import {Row} from '../../shared/grid/grid-features/Row';
import {Col} from '../../shared/grid/grid-features/Col';

import {ValidationErrorTxt} from '../../shared/form/form-features/ValidationErrorTxt';
import {withTranslation} from 'react-i18next';
function VehicleAddModal({cancel, save, t}) {
    const validationSchema = Yup.object().shape({
        licensePlate: Yup.string().required(t('errors.RequiredField')),
        type: Yup.string().required(t('errors.RequiredField')),
        volumeType: Yup.string().required(t('errors.RequiredField')),
    });

    return (
        <div>
            <Formik
                onSubmit={save}
                validationSchema={validationSchema}
                initialValues={{
                    number: '',
                    licensePlate: '',
                    type: '',
                    volumeType: '',
                    exemptedGermanToll: true,
                    mendrixId: null,
                }}
            >
                {({isSubmitting, handleSubmit}) => (
                    <Form>
                        <Modal title={t('vehicle.AddVehicle')}>
                            <Modal.Body>
                                <div role="group" aria-labelledby="type">
                                    <label>
                                        <Field type="radio" name="type" value="Charter" />
                                        {t('vehicle.Charter')}
                                    </label>
                                    <label>
                                        <Field type="radio" name="type" value="Dutch" />
                                        {t('vehicle.Dutch')}
                                    </label>
                                    <label>
                                        <Field type="radio" name="type" value="Polish" />
                                        {t('vehicle.Polish')}
                                    </label>
                                </div>
                                <br></br>
                                <div>
                                    <label>
                                        <Field type="checkbox" name="exemptedGermanToll" />
                                        {t('vehicle.ExemptedGermanToll')}
                                    </label>
                                </div>
                                <ErrorMessage name="type">
                                    {(msg) => <ValidationErrorTxt>{msg}</ValidationErrorTxt>}
                                </ErrorMessage>
                                <br></br>
                                <Row>
                                    <Col colsize="50">
                                        <FormInput name="number" placeholder={t('vehicle.Number')} />
                                    </Col>
                                    <Col colsize="50">
                                        <FormInput name="licensePlate" placeholder={t('vehicle.LicensePlate')} />
                                    </Col>
                                </Row>
                                <Row>
                                    <Col colsize="50">
                                        <FormInput name="mendrixId" placeholder={'Mendrix Id'} />
                                    </Col>
                                </Row>
                                <div role="group" aria-labelledby="volumeType">
                                    <label>
                                        <Field type="radio" name="volumeType" value="Truck" />
                                        {t('vehicle.Truck')}
                                    </label>
                                    <label>
                                        <Field type="radio" name="volumeType" value="Combi" />
                                        {t('vehicle.Combi')}
                                    </label>
                                </div>
                            </Modal.Body>
                            <Modal.Footer>
                                <CancelBtn type="button" color="default" onClick={cancel} disabled={isSubmitting}>
                                    {t('general.Cancel')}
                                </CancelBtn>
                                <Btn onClick={handleSubmit} color="success" disabled={isSubmitting}>
                                    {t('general.Submit')}
                                </Btn>
                            </Modal.Footer>
                        </Modal>
                    </Form>
                )}
            </Formik>
        </div>
    );
}

export default withTranslation()(VehicleAddModal);
