const SpaceVariables = () => `
    // --------------
    // ---- space ---
    --space-xsm: 0.25rem;
    --space-sm: 0.5rem;
    --space:  0.75rem;
    --space-md: 1rem;
    --space-lg: 1.25rem;
    --space-xl: 2.5rem;

    --list-rol-width: 200px;



    --form-control-height: 40px;
    --horz-input-padding: 12px;
`;

export default SpaceVariables;
