import {useReducer, useCallback, useEffect} from 'react';
import {getStilstand, saveStilstand} from './endpoints.js';
import errorHandler from '../../util/errorHandler.js';
import moment from 'moment';
import 'moment/locale/nl';
moment.locale('nl');

// Actions
const LOADED_DATA = 'LOADED_DATA';
const LOADING_DATA = 'LOADING_DATA';

export const initialState = {
    loading: null,
    weekStart: null,
    stilstand: [],
};

export function reducer(state, action) {
    switch (action.type) {
        case LOADING_DATA:
            return {
                ...state,
                loading: _.get(state, 'loading', 0) + 1,
            };

        case LOADED_DATA:
            return {
                ...state,
                stilstand: action.response,
                loading: _.get(state, 'loading', 0) - 1,
            };

        default:
            return state;
    }
}

function loadingStilstand() {
    return {
        type: LOADING_DATA,
    };
}

function loadedStilstand(response) {
    return {
        type: LOADED_DATA,
        response,
    };
}

export function loadStilstand(dispatch, weekStart) {
    dispatch(loadingStilstand());

    getStilstand({weekStart: weekStart})
        .then((response) => dispatch(loadedStilstand(response.data)))
        .catch((e) => errorHandler(e.response.data, true));
}

function useStilstand(weekStart) {
    const [state, dispatch] = useReducer(reducer, {...initialState, weekStart: weekStart});

    useEffect(() => {
        loadStilstand(dispatch, weekStart);
    }, [state.searchTerm, weekStart]);

    const stilstandSave = useCallback((stilstand) => saveStilstand(stilstand), []);

    return {
        ...state,
        stilstandSave,
        reload: (weekStart) => loadStilstand(dispatch, weekStart),
    };
}

export default useStilstand;
