import styled from 'styled-components';
import React from 'react';

export const CardContainer = styled.div`
    background: white;
    border: 1px solid #e4e4e4;
    border-radius: 4px;
    margin-bottom: var(--space-lg);

    ${({color}) => {
        if (color)
            return `
            display: inline-block;
            background: #f2f7fe;
            border: 1px solid #f2f7fe;
            * {
                color: var(--${color});
                font-size: 14px;
            }
        `;
        else return '';
    }}
`;

export const CardBody = styled.div`
    padding: var(--space-lg);
`;

export const Card = ({children, color}) => (
    <CardContainer color={color}>
        <CardBody>{children}</CardBody>
    </CardContainer>
);
