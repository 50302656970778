import React from 'react';
import {Btn} from '../../shared/elements/btn/Btn';
import {Icon} from '../../shared/elements/icon/Icon';
import styled from 'styled-components';
import {withTranslation} from 'react-i18next';

function LogoutButton({auth, t}) {
    return (
        <Wrapper>
            <h4>
                {t('login.loggedInAs')} {auth.user.firstName}
            </h4>
            <Btn link color="delta" onClick={() => auth.logout()}>
                <Icon icon="powersettingsnew" />
            </Btn>
        </Wrapper>
    );
}

const Wrapper = styled.div`
    display: flex;
    color: var(--delta);
    font-size: 12px;
    text-align: right;
    align-items: center;
    ${Btn} {
        padding-left: 2px;
    }
`;

export default withTranslation()(LogoutButton);
