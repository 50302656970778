import styled from 'styled-components';
import React from 'react';
import _ from 'lodash';
import * as icons from '@styled-icons/material';

const iconSetup = (type) => {
    if (!type) return 'width: 18px; ';

    return `
            width: var(--icon-size-${type}); 
        `;
};

const IconContainer = styled.span`
    svg {
        ${({type}) => iconSetup(type)};
    }
`;

const Icon = ({icon, ...props}) => {
    var iconRealName = _.findKey(icons, (_, n) => n.toUpperCase() === (icon || '').toUpperCase());

    if (iconRealName != null) {
        var RenderIcon = icons[iconRealName];
        return (
            <IconContainer>
                <RenderIcon {...props} />
            </IconContainer>
        );
    }

    return null;
};

export default IconContainer;
export {Icon};
