import React, {useContext} from 'react';
import AuthContext from './AuthContext';
import {Formik, Form} from 'formik';
import {useNavigate, Link} from 'react-router-dom';
import * as Yup from 'yup';
import LoginGrid from '../../shared/grid/gird/LoginGrid';
import {LoginBox} from '../../shared/grid/gird/LoginFeatures';
import {FormLogin} from '../../shared/form/form-control/FormLogin';
import {LoginBtn} from '../../shared/elements/btn/LoginBtn';
import styled from 'styled-components';
import {withTranslation} from 'react-i18next';
import {transformModelErrors} from '../../util/serverParser';

function Login({t}) {
    const auth = useContext(AuthContext);
    const navigate = useNavigate();

    const submit = (values, {setErrors, setSubmitting}) => {
        auth.login(values.userName, values.password)
            .then((response) => {
                if (response.role == 'Admin') {
                    navigate('/csetool');
                } else if (response.role == 'User') {
                    navigate('/weekendhours');
                } else {
                    setErrors({password: t('errors.UnknownUserType')});
                    setSubmitting(false);
                }
            })
            .catch((error) => {
                if (error.response.status === 500) {
                    setErrors({password: t('errors.ServerError')});
                    return;
                }
                if (error.response.status === 403) {
                    setErrors({password: t('errors.AccountBlocked')});
                    return;
                }
                setErrors(transformModelErrors(error.response.data, t));
                setSubmitting(false);
            });
    };

    const validationSchema = Yup.object().shape({
        userName: Yup.string().required(t('errors.RequiredField')),
        password: Yup.string().required(t('errors.RequiredField')),
    });

    return (
        <LoginGrid>
            <LoginBox.Header>
                <div>
                    <h1>{t('login.welcome')}</h1>
                </div>
                <h2>{t('login.credentials')}</h2>
            </LoginBox.Header>

            <LoginBox.Body>
                <Formik
                    initialValues={{userName: '', password: ''}}
                    validationSchema={validationSchema}
                    onSubmit={submit}
                >
                    {({isSubmitting}) => (
                        <Form>
                            <FormLogin type="text" label={t('login.username')} name="userName" />
                            <FormLogin type="password" label={t('login.password')} name="password" />
                            <LoginBtn type="submit" disabled={isSubmitting}>
                                {t('login.login')}
                            </LoginBtn>
                            <PasswordForgetBtn to="/wachtwoord-vergeten">{t('login.passwordForgot')}</PasswordForgetBtn>
                        </Form>
                    )}
                </Formik>
            </LoginBox.Body>
        </LoginGrid>
    );
}

export default withTranslation()(Login);

const PasswordForgetBtn = styled(Link)`
    color: white;
    transition: 0.6s;
    font-size: 14px;
    margin-top: var(--space-md);
    text-decoration: none;
    text-align: center;
    width: 100%;
    display: block;
    &:hover {
        color: var(--delta);
    }
`;
