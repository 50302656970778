import React, {useState, useEffect} from 'react';
import moment from 'moment';
import {MainContent} from '../../shared/grid/main-content/MainContent.js';
import Table, {StripedTable} from '../../shared/elements/table/TableFeature';
import {withTranslation} from 'react-i18next';
import StilstandSidebar from './StilstandSidebar.jsx';
import useStilstand from './StilstandState.js';
import useVehicles from '../vehicles/VehiclesState.js';
import {MessageCard} from '../../shared/custom-features/message-card/MessageCard.js';
import {Icon} from '../../shared/elements/icon/Icon.js';
import {FormSelect} from '../../shared/form/form-control/FormSelect.js';
import {Btn} from '../../shared/elements/btn/Btn';
import styled from 'styled-components';
import SearchBar from '../../shared/elements/search-bar/SearchBar.js';
import {loadStandardReasons} from './endpoints.js';
import errorHandler from '../../util/errorHandler.js';
import {NotificationManager} from 'react-notifications';
import _ from 'lodash';
import {Formik} from 'formik';

moment.locale('nl');

const weeks = _.map(_.range(-52, 1), (week) => ({
    start: moment().startOf('week').add(week, 'weeks').format('DD-MM-YY'),
    end: moment().endOf('week').add(week, 'weeks').format('DD-MM-YY'),
    number: moment().startOf('week').add(week, 'weeks').week(),
}));

function Stilstand({t}) {
    const [week, setWeek] = useState(moment().startOf('week').format('DD-MM-YY'));
    const [weekStart, setWeekStart] = useState(moment(week, 'DD-MM-YYYY').format('YYYY-MM-DD'));
    const daysInWeek = ['monday', 'tuesday', 'wednesday', 'thursday', 'friday', 'saturday', 'sunday'];

    // The "2" is for filtering on the polish vehicles
    const {loading: loadingVehicles, vehicles, search, searchTerm} = useVehicles(t, 2, 100000);
    const {stilstand, stilstandSave, reload} = useStilstand(weekStart);

    const [selectedVehicle, setSelectedVehicle] = useState(null);

    const [validationErrors, setValidationErrors] = useState(false);

    const [standardReasons, setStandardReasons] = useState([]);

    const [page, setPage] = useState(0);

    useEffect(() => {
        loadStandardReasons().then((response) => setStandardReasons(response.data));
    }, []);

    useEffect(() => {
        setWeekStart(moment(week, 'DD-MM-YYYY').format('YYYY-MM-DD'));
        setSelectedVehicle(null);
    }, [week]);

    if (loadingVehicles) return <div>{t('general.Loading')}</div>;

    // get the vehicles for which there is no stilstand
    const vehiclesWithoutStailstand = _.filter(
        vehicles,
        (vehicle) => !_.some(stilstand, (o) => o.vehicle.licensePlate == vehicle.licensePlate),
    );

    const vehiclesWithStailstand = _.filter(vehicles, (vehicle) =>
        _.some(stilstand, (o) => o.vehicle.licensePlate == vehicle.licensePlate),
    );

    //this important to do when we do the search. We do the search on vechicles
    const fitlteredStilStand = _.filter(stilstand, (st) =>
        _.some(vehiclesWithStailstand, (vs) => vs.licensePlate == st.vehicle.licensePlate),
    );

    // merge the stilstand records with artificial ones for each vehicle that doesn't have one
    const stilstandComplete = [
        ..._.map(vehiclesWithoutStailstand, (v) =>
            Object.fromEntries([
                ['vehicleStilstandId', null],
                ['vehicle', v],
                ['licensePlate', v.licensePlate],
                ['weekStart', weekStart],
                ..._.flatMap(daysInWeek, (day) => [
                    [`${day}Time`, 0],
                    [`${day}StandardReasonId`, '0'],
                    [`${day}SpecialReason`, ''],
                ]),
            ]),
        ),
        ..._.map(fitlteredStilStand, (s) =>
            Object.fromEntries([
                ['vehicleStilstandId', s.vehicleStilstandId],
                ['vehicle', s.vehicle],
                ['licensePlate', s.licensePlate],
                ['weekStart', s.weekStart],
                ..._.flatMap(daysInWeek, (day) => [
                    [`${day}Time`, s[`${day}Time`]],
                    [`${day}StandardReasonId`, s[`${day}StandardReason`]?.id ?? '0'],
                    [`${day}SpecialReason`, s[`${day}SpecialReason`]],
                ]),
            ]),
        ),
    ].sort((a, b) => -1 * (a.vehicle.number < b.vehicle.number));

    const saveStilstand = (values, options) => {
        setValidationErrors(!_.isEmpty(validate(values)));
        let valuesCopy = _.cloneDeep(values);

        _.map(
            daysInWeek,
            (day) =>
                (valuesCopy[`${day}StandardReasonId`] =
                    parseInt(valuesCopy[`${day}StandardReasonId`], 10) != 0
                        ? parseInt(valuesCopy[`${day}StandardReasonId`], 10)
                        : null),
        );
        if (_.isEmpty(validate(valuesCopy)))
            stilstandSave(valuesCopy)
                .then((res) => {
                    if (res.data > 0) NotificationManager.success(t('general.ChangesSaved'));
                })
                .catch((e) => {
                    errorHandler(e.response.data, true, t);
                })
                .finally(() => {
                    options.setSubmitting(false);
                    reload(weekStart);
                });
        else {
            NotificationManager.error(t('general.ChangesNotSaved'));
            options.setSubmitting(false);
        }
    };

    const validate = (values) => {
        let errors = {};

        _.forEach(daysInWeek, (day) => {
            if (values[`${day}Time`] && !values[`${day}Time`].toString().match(/^([0-1])?$/)) {
                if (values[`${day}Time`].toString().match(/^([0]+(\,[0-9]+))?$/))
                    values[`${day}Time`] = values[`${day}Time`].replace(',', '.');
                if (_.isNaN(parseFloat(values[`${day}Time`]?.toString()))) {
                    _.set(errors, `${day}Time`, t('errors.InvalidFormat'));
                } else if (!values[`${day}Time`].toString().match(/^([0]+(\.[0-9]+))?$/)) {
                    _.set(errors, `${day}Time`, t('errors.InvalidFormat'));
                }
            }
        });

        return errors;
    };

    function iconForStilstand(vs, day) {
        let ic = undefined;
        if (vs)
            ic =
                _.find(standardReasons, {id: vs[`${day}StandardReasonId`]})?.icon ??
                (vs[`${day}SpecialReason`] ? 'Message' : undefined);
        return ic;
    }

    return (
        <MainContent.Sidebar>
            <MainContent.Body title={t('general.StationaryTool')}>
                <br />
                <Wrapper>
                    <FormSelect
                        defaultValue={week}
                        label={t('general.SelectWeek')}
                        onChange={(evt) => setWeek(evt.target.value)}
                    >
                        {weeks.map((week) => {
                            return (
                                <option value={week.start} key={week.start}>
                                    Week {week.number} : {week.start} tot {week.end}
                                </option>
                            );
                        })}
                    </FormSelect>
                </Wrapper>
                {validationErrors && (
                    <MessageCard color="danger">
                        <Icon icon="warning" sm /> {t('explanations.ValidationErrors')}
                    </MessageCard>
                )}
            </MainContent.Body>
            <MainContent.Body>
                <Wrapper>
                    <SearchBar onSearch={search} value={searchTerm} />
                </Wrapper>
            </MainContent.Body>
            <MainContent.Body>
                <Formik
                    onSubmit={saveStilstand}
                    validateOnChange={false}
                    validateOnBlur={false}
                    initialValues={_.cloneDeep(selectedVehicle) ?? {}}
                    enableReinitialize
                >
                    {({values}) => (
                        <div>
                            <StripedTable hover>
                                <thead>
                                    <Table.Row>
                                        <Table.Header>{t('stilstand.Number')}</Table.Header>
                                        <Table.Header>{t('stilstand.LicensePlate')}</Table.Header>
                                        {_.map(daysInWeek, (day, i) => (
                                            <Table.Header key={i}>{t(`stilstand.${_.capitalize(day)}`)}</Table.Header>
                                        ))}
                                    </Table.Row>
                                </thead>
                                <tbody>
                                    {stilstandComplete.slice(page * 15, (page + 1) * 15).map((vs, ind) => (
                                        <Table.Row
                                            key={ind + page * 15}
                                            active={selectedVehicle?.licensePlate === vs?.licensePlate}
                                            onClick={() => setSelectedVehicle(vs)}
                                        >
                                            <Table.Data>{vs.vehicle.number}</Table.Data>
                                            <Table.Data>{vs.vehicle.licensePlate}</Table.Data>
                                            {_.map(daysInWeek, (day, i) => (
                                                <Table.Data key={i}>
                                                    {vs[`${day}Time`]}{' '}
                                                    {iconForStilstand(vs, day) && (
                                                        <Icon icon={iconForStilstand(vs, day)} />
                                                    )}
                                                </Table.Data>
                                            ))}
                                        </Table.Row>
                                    ))}
                                </tbody>
                            </StripedTable>
                            <StilstandSidebar
                                selectedVehicle={selectedVehicle}
                                t={t}
                                daysInWeek={daysInWeek}
                                standardReasons={standardReasons}
                                values={values}
                                iconForStilstand={iconForStilstand}
                            />
                        </div>
                    )}
                </Formik>
                <PaginationWrapper>
                    <Btn color="primary" btnIcon onClick={() => setPage(page - 1)} disabled={page <= 0}>
                        <Icon icon="arrowBack" />
                    </Btn>
                    <Btn
                        color="primary"
                        onClick={() => setPage(page + 1)}
                        disabled={(page + 1) * 15 >= stilstandComplete.length}
                        btnIcon
                    >
                        <Icon icon="arrowForward" />
                    </Btn>
                    <div>
                        <div>{t('general.Page')}</div>
                        <div>{page + 1}</div>
                        <div>{t('general.Of')}</div>
                        <div>{Math.ceil(stilstandComplete.length / 15)}</div>
                    </div>
                </PaginationWrapper>
            </MainContent.Body>
        </MainContent.Sidebar>
    );
}

const Wrapper = styled.div`
    display: flex;
    align-items: center;
    justify-content: space-between;
    > div,
    form {
        width: 100%;
    }
    form {
        margin-right: 20px;
    }
    ${Btn} {
        flex-shrink: 0;
    }
`;

const PaginationWrapper = styled.div`
    display: flex;
    align-items: center;
    > div {
        display: flex;
        padding: 0 8px;
        > div {
            padding: 2px;
        }
    }
`;

export default withTranslation()(Stilstand);
