import {useReducer, useEffect, useCallback} from 'react';
import {addUser, getUserDetails, getUsers, saveUserDetails} from './endpoints.js';

import _ from 'lodash';

// Actions
const LOADED_DATA = 'LOADED_DATA';
const LOADING_DATA = 'LOADING_DATA';
const SET_SORT = 'SET_SORT';
const SET_SEARCH = 'SET_SEARCH';
const SET_PAGE = 'SET_PAGE';

export const initialState = {
    loading: null,
    sortBy: null,
    sortByAscending: null,
    searchTerm: '',
    users: [],
    pagination: null,
    page: 1,
    pageSize: 15,
};

export function reducer(state, action) {
    switch (action.type) {
        case LOADING_DATA:
            return {
                ...state,
                loading: _.get(state, 'loading', 0) + 1,
            };

        case LOADED_DATA:
            return {
                ...state,
                users: action.response.results,
                pagination: _.pick(action.response, ['page', 'pageSize', 'totalPages', 'totalItems']),
                loading: _.get(state, 'loading', 0) - 1,
            };

        case SET_SORT:
            return {
                ...state,
                sortBy: action.sortBy,
                sortByAscending: action.sortByAscending,
            };

        case SET_SEARCH:
            return {
                ...state,
                searchTerm: action.searchTerm,
            };

        case SET_PAGE:
            return {
                ...state,
                page: action.page,
            };

        default:
            return state;
    }
}

function loadingUsers() {
    return {
        type: LOADING_DATA,
    };
}

function loadedUsers(response) {
    return {
        type: LOADED_DATA,
        response,
    };
}

export function loadUsers(dispatch, state) {
    dispatch(loadingUsers());

    getUsers({
        sortBy: state.sortBy,
        sortByAscending: state.sortByAscending,
        search: state.searchTerm,
        page: state.page,
        pageSize: state.pageSize,
    }).then((response) => {
        dispatch(loadedUsers(response.data));
    });
}

function add(values) {
    return addUser(values);
}
function getDetail(userId) {
    return getUserDetails(userId);
}
function saveDetail(userId, payload) {
    return saveUserDetails(userId, payload);
}

function useUsers(pageSize = 15) {
    const [state, dispatch] = useReducer(reducer, {...initialState, pageSize});

    useEffect(() => {
        loadUsers(dispatch, state);
    }, [state.sortBy, state.sortByAscending, state.searchTerm, state.page]);

    const sort = (sortBy) =>
        dispatch({
            type: SET_SORT,
            sortBy: sortBy,
            sortByAscending: state.sortBy === sortBy ? !state.sortByAscending : true,
        });

    const search = (searchTerm) => dispatch({type: SET_SEARCH, searchTerm: searchTerm});
    const nextPage = (page) => dispatch({type: SET_PAGE, page: page});
    const previousPage = (page) => dispatch({type: SET_PAGE, page: page});
    const userAdd = useCallback((payload) => add(payload), []);
    const getUserDetail = useCallback((userId) => getDetail(userId), []);
    const saveUserDetail = useCallback((userId, payload) => saveDetail(userId, payload), []);

    return {
        ...state,
        sort,
        search,
        nextPage,
        previousPage,
        userAdd,
        getUserDetail,
        saveUserDetail,
        reload: () => loadUsers(dispatch, state),
    };
}

export default useUsers;
