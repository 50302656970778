import {useState, useEffect} from 'react';
import {getVehicle, updateVehicle, deleteVehicle} from './endpoints.js';
import errorHandler from '../../util/errorHandler.js';

export default function useVehicle(licensePlate, t) {
    const [vehicle, setVehicle] = useState(null);

    useEffect(() => {
        getVehicle(licensePlate)
            .then((response) => setVehicle(response.data))
            .catch((e) => errorHandler(e.response.data, true, t));
    }, [licensePlate]);

    return {
        vehicle,
        loading: vehicle == null,
        update: (updatedVehicle) => updateVehicle(updatedVehicle),
        remove: () => deleteVehicle(vehicle.licensePlate),
    };
}
