import styled from 'styled-components';

export const LineSpacer = styled.div`
    display: flex;
    aling-times: center;
    flex-wrap: wrap;
    > *:not(:last-child) {
        ${({space}) => {
            if (space) {
                return ` margin-right: var(--space-${space})!important`;
            } else {
                return null;
            }
        }}
    }
`;
