import React from 'react';
import ReactDOM from 'react-dom';
import styled from 'styled-components';
import {ModalContainer, PopupDialog, ModalContentWrapper, BackDrop} from './ModalFeatures';

const Modal = ({children, title, cancel}) => {
    return ReactDOM.createPortal(
        <>
            <ModalContainer>
                <PopupDialog>
                    <ModalContentWrapper>
                        <>
                            {title && <Modal.Header>{title}</Modal.Header>}
                            <>{children}</>
                        </>
                    </ModalContentWrapper>
                </PopupDialog>
            </ModalContainer>
            <BackDrop onClick={cancel} />
        </>,
        document.getElementById('portal'),
    );
};

const ModalHeaderWrapper = styled.div`
    padding: 30px 20px;
    background: white;
    border-radius: 20px 20px 0 0;
    border-bottom: 1px solid #dddddd;
    color: var(--alpha);
`;
const ModalHeader = ({children}) => (
    <ModalHeaderWrapper>
        <h1>{children}</h1>
    </ModalHeaderWrapper>
);

const ModalBody = styled.div`
    padding: 40px 20px;
    background: white;
`;

const ModalFooterWrapper = styled.div`
    background: white;
    display: flex;
    justify-content: flex-end;
    border-radius: 0 0 20px 20px;
    padding: 0 30px 20px;
`;
const ModalFooter = ({children}) => <ModalFooterWrapper>{children}</ModalFooterWrapper>;

Modal.Header = ModalHeader;
Modal.Body = ModalBody;
Modal.Footer = ModalFooter;

export {ModalHeader, ModalBody, ModalFooter, Modal};
