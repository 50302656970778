import * as React from 'react';
import styled from 'styled-components';
import {Tab} from '../../../shared/elements/tab/Tab';

function RenderRow(props) {
    const {onClick, name, active} = props;

    return (
        <Tab.Option active={active} onClick={onClick}>
            {name}
        </Tab.Option>
    );
}

export default function RevenueList({revenues, setSelected, selected}) {
    return (
        <RevenueListContainer>
            <Tab stacked>
                {revenues.map((vehicle, index) => {
                    return (
                        <RenderRow
                            name={vehicle.vehicleNumber}
                            key={index}
                            onClick={() => setSelected(vehicle)}
                            active={selected && vehicle.vehicleNumber === selected.vehicleNumber}
                        />
                    );
                })}
            </Tab>
        </RevenueListContainer>
    );
}

export const RevenueListContainer = styled.div``;
