import React, {useState, useContext} from 'react';
import styled from 'styled-components';
import SearchBar from '../../shared/elements/search-bar/SearchBar.js';
import UserAddModal from './UserAddModal.jsx';
import UserEditModal from './UserEditModal.jsx';
import useUsers from './UserState.js';
import Table from '../../shared/elements/table/TableFeature';
import {MainContent} from '../../shared/grid/main-content/MainContent.js';
import {Btn} from '../../shared/elements/btn/Btn';
import {RouterLink} from '../../shared/elements/btn/RouterLink.js';
import {Icon} from '../../shared/elements/icon/Icon';
import errorHandler from '../../util/errorHandler.js';
import _ from 'lodash';
import {withTranslation} from 'react-i18next';

import AuthContext from '../account/AuthContext.js';
import {NotificationManager} from 'react-notifications';

function UserOverview({t}) {
    const {loading, users, pagination, sort, search, searchTerm, reload, userAdd, saveUserDetail} = useUsers();
    const [userAddModal, setUserAddModal] = useState(null);
    const [userEditModal, setUserEditModal] = useState(null);

    const auth = useContext(AuthContext);

    const addUser = (values, options) => {
        userAdd(values)
            .then(() => {
                options.setSubmitting(false);
                reload();
                setUserAddModal(null);
            })
            .catch((e) => {
                options.setSubmitting(false);
                errorHandler(e.response.data, true, t);
                setUserAddModal(null);
                reload();
            });
    };

    const editUser = (userId, values, options) => {
        saveUserDetail(userId, values)
            .catch((e) => {
                errorHandler(e.response.data, true, t);
            })
            .finally(() => {
                options.setSubmitting(false);
                reload();
                setUserEditModal(null);
            });
    };

    const resendActivationMail = (userId) => {
        auth.resendActivation(userId)
            .then(() => {
                reload();
                NotificationManager.success(t('login.resendActivationMailSuccess'));
            })
            .catch((e) => {
                errorHandler(e.response.data, true, t);
            });
    };

    return (
        <div>
            {loading == 0 && pagination ? (
                <>
                    <MainContent.Body title={t('general.Users')}>
                        <Wrapper>
                            <SearchBar onSearch={search} value={searchTerm} />
                            <Btn color="success" onClick={() => setUserAddModal(true)}>
                                {t('user.AddUser')}
                            </Btn>
                        </Wrapper>
                    </MainContent.Body>
                    <MainContent.Body>
                        {userAddModal && <UserAddModal cancel={() => setUserAddModal(null)} save={addUser} />}
                        {userEditModal && (
                            <UserEditModal
                                userId={userEditModal}
                                cancel={() => setUserEditModal(null)}
                                save={editUser}
                            />
                        )}
                        <Table>
                            <thead>
                                <Table.Row>
                                    <Table.Header
                                        onClick={() => {
                                            sort('name');
                                        }}
                                    >
                                        {t('user.FullName')}
                                    </Table.Header>
                                    <Table.Header
                                        onClick={() => {
                                            sort('role');
                                        }}
                                    >
                                        {t('user.Role')}
                                    </Table.Header>
                                    <Table.Header
                                        onClick={() => {
                                            sort('status');
                                        }}
                                    >
                                        <Table.Header />
                                        {t('user.Status')}
                                    </Table.Header>
                                    <Table.Header></Table.Header>
                                </Table.Row>
                            </thead>
                            <tbody>
                                {users && users.length === 0 && (
                                    <Table.Row>
                                        <Table.Data colSpan="3">{t('user.NoUsersFound')}</Table.Data>
                                    </Table.Row>
                                )}
                                {users &&
                                    users.length > 0 &&
                                    users.map((user, index) => (
                                        <Table.Row key={index}>
                                            <Table.Data>{user.fullName}</Table.Data>
                                            <Table.Data>
                                                {t(`roles.${_.first(user.roles)}`) || _.first(user.roles)}
                                            </Table.Data>
                                            <Table.Data>{t(`statuses.${user.status}`) || user.status} </Table.Data>

                                            <Table.Data>
                                                {user.status === 'Pending' && (
                                                    <Btn
                                                        color="primary"
                                                        link
                                                        onClick={() => resendActivationMail(user.id)}
                                                    >
                                                        {t('login.resendActivation')} <Icon icon="Send" size="sm" />
                                                    </Btn>
                                                )}
                                            </Table.Data>
                                            <Table.Data iconRow="true">
                                                <RouterLink
                                                    link
                                                    color="primary"
                                                    onClick={() => setUserEditModal(user.id)}
                                                >
                                                    <Icon icon="edit" />
                                                </RouterLink>
                                            </Table.Data>
                                        </Table.Row>
                                    ))}
                            </tbody>
                        </Table>
                    </MainContent.Body>
                </>
            ) : (
                <div>{t('general.Loading')}</div>
            )}
        </div>
    );
}

export default withTranslation()(UserOverview);

const Wrapper = styled.div`
    display: flex;
    align-items: center;
    justify-content: space-between;
    > div,
    form {
        width: 100%;
    }
    form {
        margin-right: 20px;
    }
    ${Btn} {
        flex-shrink: 0;
    }
`;
