import React, {useState} from 'react';
import styled from 'styled-components';
import {FormFile} from '../../../shared/form/form-control/FormFile.js';
import {Btn} from '../../../shared/elements/btn/Btn';
import {MainContent} from '../../../shared/grid/main-content/MainContentFeatures.js';
import {Card} from '../../../shared/elements/card/Card.js';
import {downloadVehicleRevenues as downloadVehicleRevenuesRequest} from '../endpoints.js';
import errorHandler from '../../../util/errorHandler.js';
import {withTranslation} from 'react-i18next';
import {StackedSpacer} from '../../../shared/custom-features/spacer/StackedSpacer.js';
import RevenueTable, {RevenueTableContainer} from './RevenueTable.jsx';
import {scrollSetup} from '../../../shared/custom-features/scroll/scroll';
import RevenueList, {RevenueListContainer} from './RevenueList.jsx';

function VehicleRevenue({t}) {
    const [omzetlijstFile, setOmzetLijstFile] = useState(null);
    const [showTable, setShowTable] = useState(false);
    const [loading, setLoading] = useState(false);
    const [revenues, setRevenues] = useState(null);
    const [selectedVehicle, setSelectedVehicle] = useState(null);
    const [weeknumber, setWeeknumber] = useState('');

    const onRLUpload = (event) => {
        setOmzetLijstFile(event.target.files[0]);
    };

    const resetData = () => {
        setShowTable(false);
        setOmzetLijstFile(null);
        setWeeknumber('');
        setSelectedVehicle(null);
    };

    const getRevenues = () => {
        setLoading(true);

        downloadVehicleRevenuesRequest(omzetlijstFile)
            .then((response) => {
                setRevenues(JSON.parse(new TextDecoder().decode(response.data)));
                let week = omzetlijstFile.name.replace(/\s+/g, '').match(/\d{1,2}-\d{4}/)[0];
                setWeeknumber(week);
                setShowTable(true);
            })
            .catch((e) => {
                errorHandler(e.response.data, true, t);
            })
            .finally(() => {
                setLoading(false);
            });
    };

    return (
        <>
            {!showTable && (
                <MainContent.Body>
                    <StackedSpacer space="md">
                        <Card color="primary">
                            <h3>{t('explanations.UploadOmzetlijst')}</h3>
                            <ol>
                                <li>{t('explanations.NeedsCSVAndWeekYear')}</li>
                                <li>{t('explanations.NeedsUTFEncoding')}</li>
                            </ol>
                        </Card>
                        {!loading && (
                            <FormFile
                                label={t('vehicleRevenue.UploadOmzetlijst')}
                                accept=".csv"
                                onChange={onRLUpload}
                                fileName={omzetlijstFile && omzetlijstFile.name}
                            />
                        )}
                        {omzetlijstFile !== null && (
                            <Btn
                                type="button"
                                color="success"
                                onClick={getRevenues}
                                disabled={omzetlijstFile == null || loading}
                            >
                                {loading ? t('general.Busy') : t('general.Generate')}
                            </Btn>
                        )}
                    </StackedSpacer>
                </MainContent.Body>
            )}
            {showTable && !loading && revenues && (
                <MainContent.Body title={t('general.VehicleRevenues')}>
                    <TableInfo>
                        <Btn type="button" color="primary" onClick={() => resetData()}>
                            {'Nieuwe upload'}
                        </Btn>
                        <h3>Week: {weeknumber}</h3>
                    </TableInfo>
                    <RevenueContainer>
                        <RevenueList revenues={revenues} setSelected={setSelectedVehicle} selected={selectedVehicle} />
                        <RevenueTable selectedVehicle={selectedVehicle} close={() => setShowTable(false)} />
                    </RevenueContainer>
                </MainContent.Body>
            )}
        </>
    );
}
const TableInfo = styled.div`
    margin-top: var(--space-md);
    h3 {
        float: left;
        margin-right: 10px;
    }
`;

const RevenueContainer = styled.div`
    --table-sidebar-width: 160px;
    margin-top: var(--space-md);
    display: flex;
    align-items: flex-start;
    height: 90vh;
    overflow: hidden;

    ${RevenueTableContainer} {
        height: 90vh;
        overflow: auto;
        ${scrollSetup}
        width: calc(100% - var(--table-sidebar-width));
    }
    ${RevenueListContainer} {
        height: 90vh;
        width: var(--table-sidebar-width);
        overflow: auto;
        ${scrollSetup}
        overflow-x: scroll;
    }
`;

export default withTranslation()(VehicleRevenue);
