import React, {useState} from 'react';
import {Btn} from '../../../shared/elements/btn/Btn';
import {FormSelect} from '../../../shared/form/form-control/FormSelect.js';
import {LineSpacer} from '../../../shared/custom-features/spacer/LineSpacer.js';
import {MainContent} from '../../../shared/grid/main-content/MainContentFeatures.js';
import {Card} from '../../../shared/elements/card/Card.js';
import {downloadSummaryPerWeek as downloadSummaryPerWeekRequest} from '../endpoints';
import {saveAs} from 'file-saver';
import errorHandler from '../../../util/errorHandler.js';
import {withTranslation} from 'react-i18next';
import {StackedSpacer} from '../../../shared/custom-features/spacer/StackedSpacer.js';
import moment from 'moment';
import 'moment-duration-format';
import 'moment/locale/nl';
moment.locale('nl');

const weeks = _.map(_.range(-52, 1), (week) => ({
    start: moment().startOf('week').add(week, 'weeks').format('DD-MM-YY'),
    end: moment().endOf('week').add(week, 'weeks').format('DD-MM-YY'),
    number: moment().startOf('week').add(week, 'weeks').week(),
    year: moment().startOf('week').add(week, 'weeks').format('YYYY'),
}));

function VehicleResultsOverview({t}) {
    const [loading, setLoading] = useState(false);
    const [week, setWeek] = useState({
        start: moment().startOf('week').format('DD-MM-YY'),
        end: moment().endOf('week').format('DD-MM-YY'),
        number: moment().startOf('week').week(),
        year: moment().startOf('week').format('YYYY'),
    });

    const downloadSummaryPerWeek = () => {
        setLoading(true);

        downloadSummaryPerWeekRequest(week.start)
            .then((res) => {
                let extension = 'zip';
                let tempFileName = `Summary-week-${week.number}-${week.year}`;
                let fileName = `${tempFileName}.${extension}`;

                const blob = new Blob([res.data], {
                    type: 'application/octet-stream',
                });
                saveAs(blob, fileName);
            })
            .catch((e) => {
                errorHandler(e.response.data, true, t);
            })
            .finally(() => {
                setLoading(false);
            });
    };

    return (
        <>
            <MainContent.Body>
                <StackedSpacer space="md">
                    <Card color="primary">
                        <LineSpacer space="sm">
                            <FormSelect
                                defaultValue={week.start}
                                label={t('general.SelectWeek')}
                                onChange={(evt) => setWeek(weeks.find((week) => week.start == evt.target.value))}
                            >
                                {weeks.map((week) => {
                                    return (
                                        <option value={week.start} key={week.start}>
                                            Week {week.number} : {week.start} tot {week.end}
                                        </option>
                                    );
                                })}
                            </FormSelect>
                        </LineSpacer>
                    </Card>
                    <Btn type="button" color="success" onClick={downloadSummaryPerWeek} disabled={loading}>
                        {loading ? t('general.Busy') : t('general.Generate')}
                    </Btn>
                </StackedSpacer>
            </MainContent.Body>
        </>
    );
}

export default withTranslation()(VehicleResultsOverview);
