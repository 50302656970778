import axios from 'axios';
import React, {useState, useEffect, useCallback} from 'react';
import {
    login as loginRequest,
    logout as logoutRequest,
    forgotPassword as forgotPasswordRequest,
    resetPassword as resetPasswordRequest,
    activateAccount as activateAccountRequest,
    resendActivation as resendActivationRequest,
} from './endpoints.js';

function login(dispatch, userName, password) {
    return loginRequest(userName, password).then((response) => {
        localStorage.setItem('currentUser', JSON.stringify(response.data));
        dispatch({user: response.data});
        return response.data;
    });
}

function forgotPassword(email) {
    return forgotPasswordRequest(email);
}

function logout(dispatch) {
    logoutRequest();
    // remove user from local storage to log user out
    localStorage.removeItem('currentUser');
    dispatch({user: null});
}

const AuthContext = React.createContext(null);

export function AuthProvider(props) {
    const [state, setState] = useState({user: null, loaded: false});

    const dispatch = useCallback((updated) => setState({...state, ...updated}), [state]);

    // eslint-disable-next-line
    useEffect(() => {
        var user = JSON.parse(localStorage.getItem('currentUser'));
        dispatch({user: user, loaded: true});
    }, []);

    useEffect(() => {
        axios.interceptors.response.use(
            (response) => response,
            (error) => {
                if (error.response.status === 401 && state.user) logout(dispatch);

                throw error;
            },
        );
    }, [dispatch, state.user]);

    var auth = {
        ...state,
        isLoggedIn: state.user !== null,
        login: (userName, password) => login(dispatch, userName, password),
        forgotPassword: (email) => forgotPassword(email),
        resetPassword: (userId, code, password, passwordConfirm) =>
            resetPasswordRequest(userId, code, password, passwordConfirm),
        activateAccount: (userId, code, password, passwordConfirm) =>
            activateAccountRequest(userId, code, password, passwordConfirm),
        resendActivation: (userId) => resendActivationRequest(userId),
        logout: () => logout(dispatch),
    };

    return <AuthContext.Provider value={auth}>{props.children}</AuthContext.Provider>;
}

export default AuthContext;
