import React, {useEffect, useState} from 'react';
import {Btn} from '../../../shared/elements/btn/Btn';
import {MainContent} from '../../../shared/grid/main-content/MainContentFeatures.js';
import {Card} from '../../../shared/elements/card/Card.js';
import {StackedSpacer} from '../../../shared/custom-features/spacer/StackedSpacer.js';
import {withTranslation} from 'react-i18next';
import {loadMendriXStatus, retrieveMendriXData, terminateMendriXRetrieval} from '../endpoints.js';

const Mendrix = ({t}) => {
    const [mendrixData, setMendrixData] = useState({});

    const checkMendriXStatus = async () => {
        const resp = await loadMendriXStatus();
        const output = resp.data.output;
        const status = resp.data.customStatus;
        if (status === 'Finished') {
            setMendrixData({status: 'Finished', output: t('mendrix.Finished')});
        } else if (output?.startsWith('Orchestrator function')) {
            setMendrixData({status: 'Failed', output: t('mendrix.SomethingWentWrong')});
        } else if (output === 'CancelledByUser') {
            setMendrixData({status: 'Failed', output: t('mendrix.CancelledByUser')});
        } else {
            setMendrixData({status: status, output: output});
        }
    };

    useEffect(async () => {
        await checkMendriXStatus();
    }, []);

    return (
        <MainContent.Body>
            <StackedSpacer space="md">
                <Card color="primary">
                    <h3>{t('mendrix.RetrieveData')}</h3>
                </Card>
                <Btn
                    type="button"
                    color="success"
                    onClick={async () => {
                        await retrieveMendriXData();
                        setMendrixData({status: 'Starting'});
                    }}
                    disabled={
                        !_.isEmpty(mendrixData) && mendrixData.status !== 'Failed' && mendrixData.status !== 'Finished'
                    }
                >
                    {t('mendrix.RetrieveDataShort')}
                </Btn>
                <div>
                    <Btn type="button" color="success" onClick={checkMendriXStatus}>
                        {t('mendrix.CheckStatus')}
                    </Btn>
                    {mendrixData.output ?? mendrixData.status}
                </div>
                <Btn
                    type="button"
                    color="danger"
                    onClick={async () => {
                        await terminateMendriXRetrieval();
                        await checkMendriXStatus();
                    }}
                >
                    {t('mendrix.TerminateRetrieval')}
                </Btn>
            </StackedSpacer>
        </MainContent.Body>
    );
};
export default withTranslation()(Mendrix);
