import styled from 'styled-components';
import React from 'react';

const TabBox = styled.div`
    display: flex;
    margin-bottom: 40px;
`;

const TabBoxOptionContainer = styled.div`
    cursor: pointer;
    padding: 4px 12px;
    font-size: 14px;
    border: 1px solid var(--primary);
    &:not(:last-of-type) {
        border-right: none;
    }
    color: var(--primary);
    &:first-of-type {
        border-radius: 2px 0 0 2px;
    }
    &:last-of-type {
        border-radius: 0 2px 2px 0;
    }

    &:hover {
        color: var(--primary-dark);
        background: #f4f9ff;
    }

    ${({active}) => {
        if (!active) return '';
        else
            return `
            background: var(--primary);
            color: white;
            &:hover {
                background: var(--primary-dark);
                border-color: var(--primary-dark);
                color: white;
            }
        `;
    }}
`;

const TabBoxOption = ({children, active, activeOption, ...props}) => (
    <TabBoxOptionContainer active={active} activeOption={activeOption} {...props}>
        {children}
    </TabBoxOptionContainer>
);

export {TabBox, TabBoxOption, TabBoxOptionContainer};
TabBox.Option = TabBoxOption;
