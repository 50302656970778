import styled from 'styled-components';
import ColorVariables from '../variables/ColorVariables';
import SpaceVariables from '../variables/SpaceVariables';

export const ModalContainer = styled.div`
    position: fixed;
    height: 100%;
    width: 100%;
    top: 0;
    left: 0;
    z-index: 1200;
    ${ColorVariables};
    ${SpaceVariables};
`;

export const PopupDialog = styled.div`
    border: 1px solid var(--default-border-light);
    max-width: 600px;
    width: 100%;
    display: inline-block;
    text-align: left;
    vertical-align: middle;
    position: relative;
    pointer-events: none;
    z-index: 1250;
    position: fixed;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
`;

export const ModalContentWrapper = styled.div`
    position: relative;
    display: flex;
    flex-direction: column;
    width: 100%;
    pointer-events: auto;
    background-color: #fff;
    background-clip: padding-box;
    border-radius: 20px;
    outline: 0;
`;

export const BackDrop = styled.div`
    background: #0d1b529e;
    display: block;
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 12;
    cursor: pointer;
`;
