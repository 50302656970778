import styled from 'styled-components';
import {Btn} from '../btn/Btn';
import IconContainer from '../icon/Icon';

const Table = styled.table`
    width: 100%;
    border-collapse: separate;
    border-spacing: 0 4px;
    padding: 0 2px;
    position: relative;
    z-index: var(--z-index-table);
    margin-bottom: var(--space-md);
    thead tr {
        box-shadow: none;
    }
    tbody tr td {
        ${Btn} {
            padding: 0;
            font-size: 12px;
            margin: 0;
            ${IconContainer} {
                svg {
                    width: 12px;
                }
            }
        }
    }

    ${({hover}) => {
        if (hover)
            return `
            tbody tr:hover {
                cursor: pointer;
                td {
                    background-color: var(--primary-light)!important;
                }
        }
        `;
    }}
`;

export default Table;
