import {Formik, Form, Field, ErrorMessage} from 'formik';
import React from 'react';
import * as Yup from 'yup';
import {Btn, CancelBtn} from '../../shared/elements/btn/Btn.js';
import {FormInput} from '../../shared/form/form-control/FormInput.js';
import {Modal} from '../../shared/modal/Modal.js';
import {Row} from '../../shared/grid/grid-features/Row';
import {Col} from '../../shared/grid/grid-features/Col';
import useVehicle from './VehicleState.js';
import {ValidationErrorTxt} from '../../shared/form/form-features/ValidationErrorTxt';
import errorHandler from '../../util/errorHandler.js';
import {withTranslation} from 'react-i18next';

function VehicleEditModal({licensePlate, close, reload, t}) {
    const {vehicle, loading, update} = useVehicle(licensePlate, t);

    const validationSchema = Yup.object().shape({
        licensePlate: Yup.string().required(t('errors.RequiredField')),
        type: Yup.string().required(t('errors.RequiredField')),
        volumeType: Yup.string().required(t('errors.RequiredField')),
    });

    const save = (values, options) => {
        update(values)
            .then(() => {
                options.setSubmitting(false);
                reload();
                close();
            })
            .catch((e) => {
                options.setSubmitting(false);
                errorHandler(e.response.data, true, t);
                reload();
                close();
            });
    };

    if (loading) return <span>{t('general.Loading')}</span>;

    return (
        <div>
            <Formik
                onSubmit={(values, options) => save(values, options)}
                validationSchema={validationSchema}
                initialValues={{
                    number: vehicle.number,
                    licensePlate: vehicle.licensePlate,
                    type: vehicle.type,
                    volumeType: vehicle.volumeType,
                    exemptedGermanToll: vehicle.exemptedGermanToll,
                    mendrixId: vehicle.mendrixId,
                }}
            >
                {({isSubmitting, handleSubmit}) => (
                    <Form>
                        <Modal title={t('vehicle.EditVehicle')}>
                            <Modal.Body>
                                <div role="group" aria-labelledby="type">
                                    <label>
                                        <Field type="radio" name="type" value="Charter" />
                                        {t('vehicle.Charter')}
                                    </label>
                                    <label>
                                        <Field type="radio" name="type" value="Dutch" />
                                        {t('vehicle.Dutch')}
                                    </label>
                                    <label>
                                        <Field type="radio" name="type" value="Polish" />
                                        {t('vehicle.Polish')}
                                    </label>
                                </div>
                                <br></br>
                                <div>
                                    <label>
                                        <Field type="checkbox" name="exemptedGermanToll" />
                                        {t('vehicle.ExemptedGermanToll')}
                                    </label>
                                </div>
                                <ErrorMessage name="type">
                                    {(msg) => <ValidationErrorTxt>{msg}</ValidationErrorTxt>}
                                </ErrorMessage>
                                <br></br>
                                <Row>
                                    <Col colsize="50">
                                        <FormInput name="number" placeholder={t('vehicle.Number')} />
                                    </Col>
                                    <Col colsize="50">
                                        <FormInput
                                            name="licensePlate"
                                            placeholder={t('vehicle.LicensePlate')}
                                            disabled={true}
                                        />
                                    </Col>
                                </Row>
                                <Row>
                                    <Col colsize="50">
                                        <FormInput name="mendrixId" placeholder={'Mendrix Id'} />
                                    </Col>
                                </Row>
                                <div role="group" aria-labelledby="volumeType">
                                    <label>
                                        <Field type="radio" name="volumeType" value="Truck" />
                                        {t('vehicle.Truck')}
                                    </label>
                                    <label>
                                        <Field type="radio" name="volumeType" value="Combi" />
                                        {t('vehicle.Combi')}
                                    </label>
                                </div>
                            </Modal.Body>
                            <Modal.Footer>
                                <CancelBtn type="button" color="default" onClick={close} disabled={isSubmitting}>
                                    {t('general.Cancel')}
                                </CancelBtn>
                                <Btn onClick={handleSubmit} color="success" disabled={isSubmitting}>
                                    {t('general.Submit')}
                                </Btn>
                            </Modal.Footer>
                        </Modal>
                    </Form>
                )}
            </Formik>
        </div>
    );
}

export default withTranslation()(VehicleEditModal);
