import React from 'react';

import LoginGrid from '../../shared/grid/gird/LoginGrid';
import {LoginBox} from '../../shared/grid/gird/LoginFeatures';

import {Link} from 'react-router-dom';

import styled from 'styled-components';
import {withTranslation} from 'react-i18next';

function ForgotPasswordDone({t}) {
    return (
        <LoginGrid>
            <LoginBox.Header>
                <h1>{t('login.passwordSaved')}</h1>
                <h2>{t('login.passwordSavedText')}</h2>
            </LoginBox.Header>
            <LoginBox.Body>
                <LoginSchermButton to="/login">{t('login.goBackToLogin')}</LoginSchermButton>
            </LoginBox.Body>
        </LoginGrid>
    );
}

export default withTranslation()(ForgotPasswordDone);
const LoginSchermButton = styled(Link)`
    color: white;
    transition: 0.6s;
    font-size: 14px;
    margin-top: var(--space-md);
    text-decoration: none;
    text-align: center;
    width: 100%;
    display: block;
    &:hover {
        color: var(--delta);
    }
`;
