import {useReducer, useEffect, useCallback} from 'react';
import {getVehicles, createVehicle} from './endpoints.js';
import errorHandler from '../../util/errorHandler.js';

import _ from 'lodash';

// Actions
const LOADED_DATA = 'LOADED_DATA';
const LOADING_DATA = 'LOADING_DATA';
const SET_SORT = 'SET_SORT';
const SET_SEARCH = 'SET_SEARCH';
const SET_PAGE = 'SET_PAGE';

export const initialState = {
    loading: null,
    sortBy: null,
    sortByAscending: null,
    searchTerm: '',
    vehicles: [],
    type: null,
    pagination: null,
    page: 1,
    pageSize: 15,
};

export function reducer(state, action) {
    switch (action.type) {
        case LOADING_DATA:
            return {
                ...state,
                loading: _.get(state, 'loading', 0) + 1,
            };

        case LOADED_DATA:
            return {
                ...state,
                vehicles: action.response.results,
                pagination: _.pick(action.response, ['page', 'pageSize', 'totalPages', 'totalItems']),
                loading: _.get(state, 'loading', 0) - 1,
            };

        case SET_SORT:
            return {
                ...state,
                sortBy: action.sortBy,
                sortByAscending: action.sortByAscending,
            };

        case SET_SEARCH:
            return {
                ...state,
                searchTerm: action.searchTerm,
            };

        case SET_PAGE:
            return {
                ...state,
                page: action.page,
            };

        default:
            return state;
    }
}

function loadingVehicles() {
    return {
        type: LOADING_DATA,
    };
}

function loadedVehicles(response) {
    return {
        type: LOADED_DATA,
        response,
    };
}

export function loadVehicles(dispatch, state, t) {
    dispatch(loadingVehicles());

    getVehicles({
        sortBy: state.sortBy,
        sortByAscending: state.sortByAscending,
        search: state.searchTerm,
        page: state.page,
        pageSize: state.pageSize,
        type: state.type,
    })
        .then((response) => {
            dispatch(loadedVehicles(response.data));
        })
        .catch((e) => errorHandler(e.response.data, true, t));
}

function useVehicles(t, type = null, pageSize = 15) {
    const [state, dispatch] = useReducer(reducer, {...initialState, pageSize, type});

    useEffect(() => {
        loadVehicles(dispatch, state, t);
    }, [state.sortBy, state.sortByAscending, state.searchTerm, state.page]);

    const sort = (sortBy) =>
        dispatch({
            type: SET_SORT,
            sortBy: sortBy,
            sortByAscending: state.sortBy !== sortBy || !state.sortByAscending,
        });

    const search = (searchTerm) => dispatch({type: SET_SEARCH, searchTerm: searchTerm});
    const nextPage = (page) => dispatch({type: SET_PAGE, page: page});
    const previousPage = (page) => dispatch({type: SET_PAGE, page: page});
    const vehicleAdd = useCallback((vehicle) => createVehicle(vehicle), []);

    return {
        ...state,
        sort,
        search,
        nextPage,
        previousPage,
        vehicleAdd,
        reload: () => loadVehicles(dispatch, state),
    };
}

export default useVehicles;
