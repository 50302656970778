import React, {useState} from 'react';
import styled from 'styled-components';
import SearchBar from '../../shared/elements/search-bar/SearchBar.js';
import VehicleAddModal from './VehicleAddModal.jsx';
import VehicleDeleteModal from './VehicleDeleteModal.jsx';
import VehicleEditModal from './VehicleEditModal.jsx';
import useVehicles from './VehiclesState.js';
import Table, {StripedTable} from '../../shared/elements/table/TableFeature';
import {MainContent} from '../../shared/grid/main-content/MainContent.js';
import {Btn} from '../../shared/elements/btn/Btn';
import {Icon} from '../../shared/elements/icon/Icon';
import errorHandler from '../../util/errorHandler.js';
import {withTranslation} from 'react-i18next';

function VehicleOverview({t}) {
    const {loading, vehicles, pagination, sort, search, searchTerm, reload, vehicleAdd} = useVehicles(t, _, 10000);
    const [vehicleAddModal, setVehicleAddModal] = useState(null);
    const [vehicleEditModal, setVehicleEditModal] = useState(null);
    const [vehicleDeleteModal, setVehicleDeleteModal] = useState(null);

    const addVehicle = (values, options) => {
        vehicleAdd(values)
            .then(() => {
                options.setSubmitting(false);
                reload();
                setVehicleAddModal(null);
            })
            .catch((e) => {
                options.setSubmitting(false);
                errorHandler(e.response.data, true, t);
                setVehicleAddModal(null);
                reload();
            });
    };

    return (
        <div>
            {loading == 0 && pagination ? (
                <>
                    <MainContent.Body title={t('vehicle.VehicleOverview')}>
                        <Wrapper>
                            <SearchBar onSearch={search} value={searchTerm} />
                            <Btn color="success" onClick={() => setVehicleAddModal(true)}>
                                {t('vehicle.AddVehicle')}
                            </Btn>
                        </Wrapper>
                    </MainContent.Body>
                    <MainContent.Body>
                        {vehicleAddModal && (
                            <VehicleAddModal cancel={() => setVehicleAddModal(null)} save={addVehicle} />
                        )}
                        {vehicleEditModal && (
                            <VehicleEditModal
                                licensePlate={vehicleEditModal}
                                close={() => setVehicleEditModal(null)}
                                reload={reload}
                            />
                        )}
                        {vehicleDeleteModal && (
                            <VehicleDeleteModal
                                licensePlate={vehicleDeleteModal}
                                close={() => setVehicleDeleteModal(null)}
                                reload={reload}
                            />
                        )}
                        <StripedTable hover>
                            <thead>
                                <Table.Row>
                                    <Table.Header
                                        onClick={() => {
                                            sort('number');
                                        }}
                                    >
                                        {t('vehicle.Number')}
                                    </Table.Header>
                                    <Table.Header
                                        onClick={() => {
                                            sort('licensePlate');
                                        }}
                                    >
                                        {t('vehicle.LicensePlate')}
                                    </Table.Header>
                                    <Table.Header
                                        onClick={() => {
                                            sort('mendrixId');
                                        }}
                                    >
                                        {'Mendrix Id'}
                                    </Table.Header>
                                    <Table.Header
                                        onClick={() => {
                                            sort('type');
                                        }}
                                    >
                                        {t('vehicle.Type')}
                                    </Table.Header>
                                    <Table.Header
                                        onClick={() => {
                                            sort('volumeType');
                                        }}
                                    >
                                        {t('vehicle.VolumeType')}
                                    </Table.Header>
                                    <Table.Header>{t('vehicle.ExemptedGermanToll')}</Table.Header>
                                    <Table.Header></Table.Header>
                                </Table.Row>
                            </thead>
                            <tbody>
                                {vehicles?.length === 0 && (
                                    <Table.Row>
                                        <Table.Data colSpan="3">{t('vehicle.NoVehiclesFound')}</Table.Data>
                                    </Table.Row>
                                )}
                                {vehicles?.length > 0 &&
                                    vehicles.map((vehicle, index) => (
                                        <Table.Row key={index}>
                                            <Table.Data>{vehicle.number}</Table.Data>
                                            <Table.Data>{vehicle.licensePlate}</Table.Data>
                                            <Table.Data>{vehicle.mendrixId ?? ''}</Table.Data>
                                            <Table.Data>{t(`vehicle.${vehicle.type}`) || vehicle.type}</Table.Data>
                                            <Table.Data>
                                                {t(`vehicle.${vehicle.volumeType}`) || vehicle.volumeType}
                                            </Table.Data>
                                            <Table.Data>
                                                {vehicle.exemptedGermanToll && <Icon icon="check" />}
                                            </Table.Data>
                                            <Table.Data iconRow="true">
                                                <Btn
                                                    link
                                                    color="primary"
                                                    onClick={() => setVehicleEditModal(vehicle.licensePlate)}
                                                >
                                                    <Icon icon="edit" />
                                                </Btn>
                                            </Table.Data>
                                            <Table.Data iconRow="true">
                                                <Btn
                                                    link
                                                    color="primary"
                                                    onClick={() => setVehicleDeleteModal(vehicle.licensePlate)}
                                                >
                                                    <Icon icon="delete" color="var(--danger)" />
                                                </Btn>
                                            </Table.Data>
                                        </Table.Row>
                                    ))}
                            </tbody>
                        </StripedTable>
                    </MainContent.Body>
                </>
            ) : (
                <div>{t('general.Loading')}</div>
            )}
        </div>
    );
}

export default withTranslation()(VehicleOverview);

const Wrapper = styled.div`
    display: flex;
    align-items: center;
    justify-content: space-between;
    > div,
    form {
        width: 100%;
    }
    form {
        margin-right: 20px;
    }
    ${Btn} {
        flex-shrink: 0;
    }
`;
