import React, {useState} from 'react';
import {FormFile} from '../../../shared/form/form-control/FormFile.js';
import {LineSpacer} from '../../../shared/custom-features/spacer/LineSpacer.js';
import {Btn} from '../../../shared/elements/btn/Btn';
import {MainContent} from '../../../shared/grid/main-content/MainContentFeatures.js';
import {Card} from '../../../shared/elements/card/Card.js';
import {downloadWeekstaten as downloadWeekstatenRequest} from '../endpoints.js';
import {saveAs} from 'file-saver';
import errorHandler from '../../../util/errorHandler.js';
import {withTranslation} from 'react-i18next';
import {StackedSpacer} from '../../../shared/custom-features/spacer/StackedSpacer.js';

function Weekstaten({t}) {
    const [rittenlijstFile, setRittenlijstFile] = useState(null);
    const [loading, setLoading] = useState(false);

    const onRLUpload = (event) => {
        setRittenlijstFile(event.target.files[0]);
    };

    const downloadWeekstaten = () => {
        setLoading(true);

        downloadWeekstatenRequest(rittenlijstFile)
            .then((res) => {
                let extension = 'zip';
                let tempFileName = `Weekstaten ${rittenlijstFile.name.replace('.csv', '')}`;
                let fileName = `${tempFileName}.${extension}`;

                const blob = new Blob([res.data], {
                    type: 'application/octet-stream',
                });
                saveAs(blob, fileName);
            })
            .catch((e) => {
                errorHandler(e.response.data, true, t);
            })
            .finally(() => {
                setLoading(false);
            });
    };

    return (
        <>
            <MainContent.Body>
                <StackedSpacer space="md">
                    <Card color="primary">
                        <h3>{t('explanations.UploadRittenlijst')}</h3>
                        <ol>
                            <li>{t('explanations.NeedsCSVAndWeekYear')}</li>
                            <li>{t('explanations.NeedsUTFEncoding')}</li>
                        </ol>
                    </Card>
                    <LineSpacer space="sm">
                        {!loading && (
                            <FormFile
                                label={t('weekstaten.UploadRittenlijst')}
                                accept=".csv"
                                onChange={onRLUpload}
                                fileName={rittenlijstFile && rittenlijstFile.name}
                            />
                        )}
                    </LineSpacer>
                    {rittenlijstFile !== null && (
                        <Btn
                            type="button"
                            color="success"
                            onClick={downloadWeekstaten}
                            disabled={rittenlijstFile == null || loading}
                        >
                            {loading ? t('general.Busy') : t('general.Generate')}
                        </Btn>
                    )}
                </StackedSpacer>
            </MainContent.Body>
        </>
    );
}

export default withTranslation()(Weekstaten);
