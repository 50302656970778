import axios from 'axios';
import _ from 'lodash';

export function getOvertimes(params) {
    return axios.get('/api/vehicles/overtimes', {params: params}).then((response) => {
        _.forEach(response.data, (overtime) => {
            overtime.saturdayFrom = overtime.saturdayFrom?.substring(0, 5);
            overtime.saturdayTo = overtime.saturdayTo?.substring(0, 5);
            overtime.sundayFrom = overtime.sundayFrom?.substring(0, 5);
            overtime.sundayTo = overtime.sundayTo?.substring(0, 5);
            overtime.holidaysFrom = overtime.holidaysFrom?.substring(0, 5);
            overtime.holidaysTo = overtime.holidaysTo?.substring(0, 5);
        });

        return response;
    });
}

export function updateOvertimes(overtimes) {
    _.forEach(overtimes, (o) => (o.vehicleOvertimeId = o.vehicleOvertimeId ? o.vehicleOvertimeId : null));
    return axios.post('/api/vehicles/overtimes', {overtimes: overtimes});
}
