import React, {useState} from 'react';
import styled from 'styled-components';
import {withTranslation} from 'react-i18next';
import Weekstaten from './weekstaten/Weekstaten';
import VehicleRevenues from './revenues/VehicleRevenues';
import VehicleResultsOverview from './results/VehicleResultsOverview';
import MendriX from './mendrix/MendriX';
import {TabBox} from '../../shared/elements/tab/TabBox';
import SummaryPerWeek from './results/SummaryPerWeek.jsx';

function CseTool({t}) {
    const [tab, setTab] = useState('MendriX');

    return (
        <Wrapper>
            <TabBox>
                <TabBox.Option active={tab === 'MendriX'} onClick={() => setTab('MendriX')}>
                    {t('general.MendriX')}
                </TabBox.Option>
                <TabBox.Option active={tab === 'Weekstaten'} onClick={() => setTab('Weekstaten')}>
                    {t('general.Weekstaten')}
                </TabBox.Option>
                <TabBox.Option active={tab === 'VehicleRevenues'} onClick={() => setTab('VehicleRevenues')}>
                    {t('general.VehicleRevenues')}
                </TabBox.Option>
                <TabBox.Option
                    active={tab === 'VehicleResultsOverview'}
                    onClick={() => setTab('VehicleResultsOverview')}
                >
                    {t('resultsOverview.GenerateResultsOverview')}
                </TabBox.Option>
                <TabBox.Option active={tab === 'SummaryPerWeek'} onClick={() => setTab('SummaryPerWeek')}>
                    {t('resultsOverview.GenerateSummaryPerWeek')}
                </TabBox.Option>
            </TabBox>
            {tab === 'MendriX' && <MendriX />}
            {tab === 'Weekstaten' && <Weekstaten />}
            {tab === 'VehicleRevenues' && <VehicleRevenues />}
            {tab === 'VehicleResultsOverview' && <VehicleResultsOverview />}
            {tab === 'SummaryPerWeek' && <SummaryPerWeek />}
        </Wrapper>
    );
}

export default withTranslation()(CseTool);
const Wrapper = styled.div`
    ol {
        margin: 0;
        margin-top: var(--space-sm);
        padding-left: var(--space-lg);
    }
`;
