import React from 'react';
import Logo from './img/logo-cse.png';
import {NavLink} from 'react-router-dom';
import {Topbar} from './shared/grid/main-content/Topbar';
import LogoutButton from './modules/account/LogoutButton';
import TranslationSwitch from './modules/account/TranslationSwitch';
import {withTranslation} from 'react-i18next';

function NavBar({auth, t}) {
    return (
        <Topbar>
            <Topbar.Nav>
                <NavLink to="/">
                    <img src={Logo} alt="LogoImgLogin" />
                </NavLink>
                {auth.user.role === 'Admin' && (
                    <Topbar.Option to="/csetool" activeclassname="active">
                        <i className="material-icons md-24" />
                        {t('general.CseTool')}
                    </Topbar.Option>
                )}
                {auth.user.role === 'Admin' && (
                    <Topbar.Option to="/users" activeclassname="active">
                        <i className="material-icons md-24" />
                        {t('general.Users')}
                    </Topbar.Option>
                )}
                {auth.user.role === 'Admin' && (
                    <Topbar.Option to="/vehicles" activeclassname="active">
                        <i className="material-icons md-24" />
                        {t('general.Vehicles')}
                    </Topbar.Option>
                )}
                {auth.user.role === 'Admin' && (
                    <Topbar.Option to="/variables" activeclassname="active">
                        <i className="material-icons md-24" />
                        {t('general.Variables')}
                    </Topbar.Option>
                )}
                {(auth.user.role === 'Admin' || auth.user.role === 'User') && (
                    <Topbar.Option to="/weekendhours" activeclassname="active">
                        <i className="material-icons md-24" />
                        {t('general.VehicleOvertimes')}
                    </Topbar.Option>
                )}
                {(auth.user.role === 'Admin' || auth.user.role === 'User') && (
                    <Topbar.Option to="/stationarytool" activeclassname="active">
                        <i className="material-icons md-24" />
                        {t('general.StationaryTool')}
                    </Topbar.Option>
                )}
            </Topbar.Nav>
            <Topbar.Nav>
                <LogoutButton auth={auth} />
                <TranslationSwitch />
            </Topbar.Nav>
        </Topbar>
    );
}

export default withTranslation()(NavBar);
