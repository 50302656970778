import React, {useState, useCallback, useEffect} from 'react';
import {withTranslation} from 'react-i18next';
import {Sidebar} from '../../shared/grid/main-content/MainContentSidebar.js';
import {Field, useFormikContext, Form} from 'formik';
import {FormInput} from '../../shared/form/form-control/FormInput.js';
import {FormLabel} from '../../shared/form/form-features/FormLabel.js';

import _ from 'lodash';
import {FormSelectContainer} from '../../shared/form/form-control/FormSelect.js';
import {Icon} from '../../shared/elements/icon/Icon.js';
import {FormGroup} from '../../shared/form/form-features/FormGroup.js';
import styled from 'styled-components';
import {FormTextarea} from '../../shared/form/form-control/FormTextarea.js';

function StilstandSidebar({t, selectedVehicle, i18n, standardReasons, daysInWeek, values, iconForStilstand}) {
    if (!selectedVehicle)
        return (
            <Sidebar>
                <Sidebar.Header>{t('stilstand.SelectAVehicle')}</Sidebar.Header>
            </Sidebar>
        );

    return (
        <Sidebar>
            <Sidebar.Header>{`${selectedVehicle.vehicle.number} - ${selectedVehicle.licensePlate}`}</Sidebar.Header>
            <Form>
                <Sidebar.Body>
                    {_.map(daysInWeek, (day, i) => (
                        <FormDay
                            key={i}
                            day={day}
                            standardReasons={standardReasons}
                            i18n={i18n}
                            t={t}
                            values={values}
                            iconForStilstand={iconForStilstand}
                        />
                    ))}
                    <ValueChangeListener />
                </Sidebar.Body>
            </Form>
        </Sidebar>
    );
}

const FormDay = ({day, standardReasons, i18n, t, values, iconForStilstand}) => {
    let valuesCopy = _.cloneDeep(values);

    valuesCopy[`${day}StandardReasonId`] =
        parseInt(valuesCopy[`${day}StandardReasonId`], 10) != 0
            ? parseInt(valuesCopy[`${day}StandardReasonId`], 10)
            : null;

    function sortedStandartReasons(standardReasons) {
        return i18n.language === 'nl'
            ? _.sortBy(standardReasons, ['dutchName'])
            : _.sortBy(standardReasons, ['englishName']);
    }

    return (
        <Wrapper>
            <FormLabel>{t(`${_.capitalize(day)} Stilstand`)}</FormLabel>
            <FieldGroup>
                <FormInput
                    name={`${day}Time`}
                    placeholder="0"
                    type="text"
                    suffixIcon={iconForStilstand(valuesCopy, day)}
                />
                <FormGroup>
                    <FormSelectContainer>
                        <Icon icon="ArrowDropDown" />
                        <Field size="sm" name={`${day}StandardReasonId`} as="select">
                            <option key={0} value="0">
                                {t('stilstand.SelectStandartReason')}
                            </option>
                            {_.map(sortedStandartReasons(standardReasons), (sr, i) => (
                                <option key={i + 1} value={sr.id}>
                                    {i18n.language === 'nl' ? sr.dutchName : sr.englishName}
                                </option>
                            ))}
                        </Field>
                    </FormSelectContainer>
                </FormGroup>
            </FieldGroup>

            <FormTextarea rows="3" name={`${day}SpecialReason`} placeholder={t('stilstand.SpecialNotes')} type="text" />
        </Wrapper>
    );
};

export default withTranslation()(StilstandSidebar);

const ValueChangeListener = () => {
    const {submitForm, values} = useFormikContext();
    const [initialized, setInitialized] = useState(false);
    const submit = useCallback(_.debounce(submitForm, 500), [submitForm]);

    useEffect(() => {
        if (initialized) {
            submit();
        } else {
            setInitialized(true);
        }
    }, [values, submit, setInitialized]);

    return null;
};

const Wrapper = styled.div`
    margin-bottom: 40px;
`;

const FieldGroup = styled.div`
    display: flex;
    margin: 0 -4px;
    > div {
        width: 100%;
        padding: 0 4px;
        &:first-of-type {
            flex: 0 0 25%;
            max-width: 25%;
        }
        &:last-of-type {
            flex: 0 0 75%;
            max-width: 75%;
        }
    }
`;
