import styled from 'styled-components';
import React from 'react';

export const colSetup = ({width}) => `
    flex: 0 0 ${width || '100%'};
    max-width: ${width || '100%'};
`;

const ColContainer = styled.div`
    position: relative;
    width: 100%;
    min-height: 1px;
    padding-right: 1rem;
    padding-left: 1rem;
    ${({colsize}) => {
        if (colsize) {
            return ` ${colSetup({width: `${colsize}%`})};`;
        } else {
            return 'width: 10px';
        }
    }}
`;

const ColWrapper = ({colsize, children}) => <ColContainer colsize={colsize}> {children}</ColContainer>;

const Col = ({children, title, Editable, colsize}) => (
    <>
        {colsize && (
            <ColWrapper title={title} Editable={Editable} colsize={colsize}>
                {children}
            </ColWrapper>
        )}
    </>
);
export {Col, ColContainer, ColWrapper};
