import React, {useContext, useState, useEffect, useCallback} from 'react';
import useOvertimes from './OvertimesState.js';
import useVehicles from '../vehicles/VehiclesState.js';
import AuthContext from '../account/AuthContext.js';
import Table, {StripedTable} from '../../shared/elements/table/TableFeature';
import {MainContent} from '../../shared/grid/main-content/MainContent.js';
import {Formik, Form, useFormikContext, getIn} from 'formik';
import {NotificationManager} from 'react-notifications';
import {FormInput} from '../../shared/form/form-control/FormInput.js';
import {Btn} from '../../shared/elements/btn/Btn';
import errorHandler from '../../util/errorHandler.js';
import {stringifyNulls} from '../../util/serverParser.js';
import {isValidTime} from '../../util/validation.js';
import styled from 'styled-components';
import _ from 'lodash';
import 'react-notifications/lib/notifications.css';
import moment from 'moment';
import 'moment-duration-format';
import 'moment/locale/nl';
import {FormSelect} from '../../shared/form/form-control/FormSelect.js';
import {withTranslation} from 'react-i18next';
import {Icon} from '../../shared/elements/icon/Icon.js';
import {MessageCard} from '../../shared/custom-features/message-card/MessageCard.js';
moment.locale('nl');

const weeks = _.map(_.range(-52, 1), (week) => ({
    start: moment().startOf('week').add(week, 'weeks').format('DD-MM-YY'),
    end: moment().endOf('week').add(week, 'weeks').format('DD-MM-YY'),
    number: moment().startOf('week').add(week, 'weeks').week(),
}));

function VehicleOvertimes({t}) {
    const [week, setWeek] = useState(moment().startOf('week').format('DD-MM-YY'));
    const weekStart = moment(week, 'DD-MM-YYYY').format('YYYY-MM-DD');
    const auth = useContext(AuthContext);
    const [page, setPage] = useState(0);

    // The "2" is for filtering on the polish vehicles
    const {loading: loadingVehicles, vehicles} = useVehicles(t, 2, 100000);
    const {loading: loadingOvertimes, overtimes, update} = useOvertimes(weekStart);

    const [validationErrors, setValidationErrors] = useState(false);

    const userInput = (values, options) => {
        setValidationErrors(values.overtimes.some((o) => !_.isEmpty(validate(o))));

        update(values.overtimes.filter((o) => _.isEmpty(validate(o))))
            .then((res) => {
                if (res.data > 0) NotificationManager.success(t('general.ChangesSaved'));
            })
            .catch((e) => {
                errorHandler(e.response.data, true, t);
            })
            .finally(() => {
                options.setSubmitting(false);
            });
    };

    if (loadingVehicles || loadingOvertimes) return <div>{t('general.Loading')}</div>;

    const vehiclesMissing = _.filter(
        vehicles,
        (vehicle) => !_.some(overtimes, (o) => o.vehicle.licensePlate == vehicle.licensePlate),
    );
    const overtimesComplete = _.concat(
        overtimes,
        _.map(vehiclesMissing, (v) => ({
            vehicleOvertimeId: null,
            vehicle: v,
            licensePlate: v.licensePlate,
            weekStart: weekStart,
            saturdayFrom: null,
            saturdayTo: null,
            sundayFrom: null,
            sundayTo: null,
            holidaysFrom: null,
            holidaysTo: null,
            notesAdmin: null,
            notesUser: null,
        })),
    ).sort((a, b) => -1 * (a.vehicle.number < b.vehicle.number));

    const validate = (overtime) => {
        let errors = {};

        if (
            !isValidTime(overtime.saturdayFrom, true) ||
            (!overtime.saturdayFrom && isValidTime(overtime.saturdayTo, false))
        )
            _.set(errors, 'saturdayFrom', t('errors.InvalidFormat'));
        if (
            !isValidTime(overtime.saturdayTo, true) ||
            (!overtime.saturdayTo && isValidTime(overtime.saturdayFrom, false))
        )
            _.set(errors, 'saturdayTo', t('errors.InvalidFormat'));
        if (!isValidTime(overtime.sundayFrom, true) || (!overtime.sundayFrom && isValidTime(overtime.sundayTo, false)))
            _.set(errors, 'sundayFrom', t('errors.InvalidFormat'));
        if (!isValidTime(overtime.sundayTo, true) || (!overtime.sundayTo && isValidTime(overtime.sundayFrom, false)))
            _.set(errors, 'sundayTo', t('errors.InvalidFormat'));
        if (
            !isValidTime(overtime.holidaysFrom, true) ||
            (!overtime.holidaysFrom && isValidTime(overtime.holidaysTo, false))
        )
            _.set(errors, 'holidaysFrom', t('errors.InvalidFormat'));
        if (
            !isValidTime(overtime.holidaysTo, true) ||
            (!overtime.holidaysTo && isValidTime(overtime.holidaysFrom, false))
        )
            _.set(errors, 'holidaysTo', t('errors.InvalidFormat'));

        return errors;
    };

    const subtotalHours = (overtime) => {
        const saturdayTotal =
            isValidTime(overtime.saturdayTo) && isValidTime(overtime.saturdayFrom)
                ? overtime.saturdayTo >= overtime.saturdayFrom
                    ? moment.duration(overtime.saturdayTo) - moment.duration(overtime.saturdayFrom)
                    : moment.duration(24, 'hours') +
                      moment.duration(overtime.saturdayTo) -
                      moment.duration(overtime.saturdayFrom)
                : 0;
        const sundayTotal =
            isValidTime(overtime.sundayTo) && isValidTime(overtime.sundayFrom)
                ? overtime.sundayTo >= overtime.sundayFrom
                    ? moment.duration(overtime.sundayTo) - moment.duration(overtime.sundayFrom)
                    : moment.duration(24, 'hours') +
                      moment.duration(overtime.sundayTo) -
                      moment.duration(overtime.sundayFrom)
                : 0;
        const holidaysTotal =
            isValidTime(overtime.holidaysTo) && isValidTime(overtime.holidaysFrom)
                ? overtime.holidaysTo >= overtime.holidaysFrom
                    ? moment.duration(overtime.holidaysTo) - moment.duration(overtime.holidaysFrom)
                    : moment.duration(24, 'hours') +
                      moment.duration(overtime.holidaysTo) -
                      moment.duration(overtime.holidaysFrom)
                : 0;

        const totalMilliseconds = saturdayTotal + sundayTotal + holidaysTotal;
        return moment.duration(totalMilliseconds);
    };

    const totalHoursOn = (overtimes, from, to) => {
        const totalMilliseconds = _.sumBy(overtimes, (overtime) => {
            return isValidTime(overtime[to]) && isValidTime(overtime[from])
                ? overtime[to] >= overtime[from]
                    ? moment.duration(overtime[to]) - moment.duration(overtime[from])
                    : moment.duration(24, 'hours') + moment.duration(overtime[to]) - moment.duration(overtime[from])
                : 0;
        });
        return moment.duration(totalMilliseconds);
    };

    const totalHours = (overtimes) => {
        const totalMilliseconds = _.sumBy(overtimes, (overtime) => {
            return subtotalHours(overtime).as('milliseconds');
        });

        return moment.duration(totalMilliseconds);
    };

    return (
        <div>
            <MainContent.Body title={t('general.VehicleOvertimes')}>
                <br></br>
                <Wrapper>
                    <FormSelect
                        defaultValue={week}
                        label={t('general.SelectWeek')}
                        onChange={(evt) => setWeek(evt.target.value)}
                    >
                        {weeks.map((week) => {
                            return (
                                <option value={week.start} key={week.start}>
                                    Week {week.number} : {week.start} tot {week.end}
                                </option>
                            );
                        })}
                    </FormSelect>
                </Wrapper>
                {validationErrors && (
                    <MessageCard color="danger">
                        <Icon icon="warning" sm /> {t('explanations.ValidationErrors')}
                    </MessageCard>
                )}
            </MainContent.Body>
            <MainContent.Body>
                <Formik
                    onSubmit={userInput}
                    validateOnChange={false}
                    validateOnBlur={false}
                    initialValues={{
                        overtimes: _.map(overtimesComplete, (x) => stringifyNulls(x)),
                    }}
                >
                    {({values}) => (
                        <Form>
                            <StripedTable hover>
                                <thead>
                                    <Table.Row>
                                        <Table.Header>{t('vehicle.Number')}</Table.Header>
                                        <Table.Header>{t('vehicle.LicensePlate')}</Table.Header>
                                        <Table.Header>{t('general.Saturday')}</Table.Header>
                                        <Table.Header>{t('general.Sunday')}</Table.Header>
                                        <Table.Header>{t('general.Holidays')}</Table.Header>
                                        <Table.Header>{t('general.NotesAdmin')}</Table.Header>
                                        <Table.Header>{t('general.NotesUser')}</Table.Header>
                                        <Table.Header>{t('general.Total')}</Table.Header>
                                    </Table.Row>
                                </thead>
                                <tbody>
                                    {values.overtimes.length > 0 ? (
                                        <>
                                            {values.overtimes
                                                .slice(page * 15, (page + 1) * 15)
                                                .map((overtime, index) => {
                                                    index += page * 15;

                                                    const errors = validate(overtime);

                                                    return (
                                                        <Table.Row key={index}>
                                                            <Table.Data>{overtime.vehicle.number} </Table.Data>
                                                            <Table.Data>{overtime.vehicle.licensePlate} </Table.Data>
                                                            <Table.Data>
                                                                <Table.FormField>
                                                                    <FormInput
                                                                        size="xsm"
                                                                        name={`overtimes.${index}.saturdayFrom`}
                                                                        placeholder="00:00"
                                                                        borderError={getIn(errors, 'saturdayFrom')}
                                                                    />
                                                                    <div>{t('general.Till').toLowerCase()}</div>
                                                                    <FormInput
                                                                        size="xsm"
                                                                        name={`overtimes.${index}.saturdayTo`}
                                                                        placeholder="00:00"
                                                                        borderError={getIn(errors, 'saturdayTo')}
                                                                    />
                                                                </Table.FormField>
                                                            </Table.Data>

                                                            <Table.Data>
                                                                <Table.FormField>
                                                                    <FormInput
                                                                        size="xsm"
                                                                        name={`overtimes.${index}.sundayFrom`}
                                                                        placeholder="00:00"
                                                                        borderError={getIn(errors, 'sundayFrom')}
                                                                    />
                                                                    <div> {t('general.Till').toLowerCase()}</div>
                                                                    <FormInput
                                                                        size="xsm"
                                                                        name={`overtimes.${index}.sundayTo`}
                                                                        placeholder="00:00"
                                                                        borderError={getIn(errors, 'sundayTo')}
                                                                    />
                                                                </Table.FormField>
                                                            </Table.Data>

                                                            <Table.Data>
                                                                <Table.FormField>
                                                                    <FormInput
                                                                        size="xsm"
                                                                        name={`overtimes.${index}.holidaysFrom`}
                                                                        placeholder="00:00"
                                                                        borderError={getIn(errors, 'holidaysFrom')}
                                                                    />
                                                                    <div>{t('general.Till').toLowerCase()}</div>
                                                                    <FormInput
                                                                        size="xsm"
                                                                        name={`overtimes.${index}.holidaysTo`}
                                                                        placeholder="00:00"
                                                                        borderError={getIn(errors, 'holidaysTo')}
                                                                    />
                                                                </Table.FormField>
                                                            </Table.Data>

                                                            <Table.Data>
                                                                <FormInput
                                                                    size="sm"
                                                                    name={`overtimes.${index}.notesAdmin`}
                                                                    disabled={auth.user.role !== 'Admin'}
                                                                />
                                                            </Table.Data>

                                                            <Table.Data>
                                                                <FormInput
                                                                    size="sm"
                                                                    name={`overtimes.${index}.notesUser`}
                                                                    disabled={auth.user.role !== 'User'}
                                                                />
                                                            </Table.Data>

                                                            <Table.Data>
                                                                <strong>
                                                                    {subtotalHours(overtime).format('HH:mm', {
                                                                        trim: false,
                                                                    })}
                                                                </strong>
                                                            </Table.Data>
                                                        </Table.Row>
                                                    );
                                                })}

                                            <Table.Row>
                                                <Table.Data />
                                                <Table.Data />
                                                <Table.Data>
                                                    <strong>
                                                        {totalHoursOn(
                                                            values.overtimes,
                                                            'saturdayFrom',
                                                            'saturdayTo',
                                                        ).format('HH:mm', {trim: false})}
                                                    </strong>
                                                </Table.Data>
                                                <Table.Data>
                                                    <strong>
                                                        {totalHoursOn(
                                                            values.overtimes,
                                                            'sundayFrom',
                                                            'sundayTo',
                                                        ).format('HH:mm', {trim: false})}
                                                    </strong>
                                                </Table.Data>
                                                <Table.Data>
                                                    <strong>
                                                        {totalHoursOn(
                                                            values.overtimes,
                                                            'holidaysFrom',
                                                            'holidaysTo',
                                                        ).format('HH:mm', {trim: false})}
                                                    </strong>
                                                </Table.Data>
                                                <Table.Data />
                                                <Table.Data />
                                                <Table.Data>
                                                    <strong>
                                                        {totalHours(values.overtimes).format('HH:mm', {
                                                            trim: false,
                                                        })}
                                                    </strong>
                                                </Table.Data>
                                            </Table.Row>
                                        </>
                                    ) : (
                                        <Table.Row>
                                            <Table.Data colSpan="3">{t('vehicle.NoVehiclesFound')}</Table.Data>
                                        </Table.Row>
                                    )}
                                </tbody>
                            </StripedTable>

                            <ValueChangeListener />
                        </Form>
                    )}
                </Formik>
                <PaginationWrapper>
                    <Btn color="primary" btnIcon onClick={() => setPage(page - 1)} disabled={page <= 0}>
                        <Icon icon="arrowBack" />
                    </Btn>
                    <Btn
                        color="primary"
                        onClick={() => setPage(page + 1)}
                        disabled={(page + 1) * 15 >= overtimesComplete.length}
                        btnIcon
                    >
                        <Icon icon="arrowForward" />
                    </Btn>
                    <div>
                        <div>{t('general.Page')}</div>
                        <div>{page + 1}</div>
                        <div>{t('general.Of')}</div>
                        <div>{Math.ceil(overtimesComplete.length / 15)}</div>
                    </div>
                </PaginationWrapper>
            </MainContent.Body>
        </div>
    );
}

export default withTranslation()(VehicleOvertimes);

const ValueChangeListener = () => {
    const {submitForm, values} = useFormikContext();
    const [initialized, setInitialized] = useState(false);
    const submit = useCallback(_.debounce(submitForm, 1500), [submitForm]);

    useEffect(() => {
        if (initialized) {
            submit();
        } else {
            setInitialized(true);
        }
    }, [values, submit, setInitialized]);

    return null;
};

const Wrapper = styled.div`
    display: flex;
    align-items: center;
    justify-content: space-between;
    > div,
    form {
        width: 100%;
    }
    form {
        margin-right: 20px;
    }
    ${Btn} {
        flex-shrink: 0;
    }
`;

const PaginationWrapper = styled.div`
    display: flex;
    align-items: center;
    > div {
        display: flex;
        padding: 0 8px;
        > div {
            padding: 2px;
        }
    }
`;
