import styled from 'styled-components';
import IconContainer from '../icon/Icon';

export const btnStyling = () => `
    font-size: 14px;
    border-radius: 2px;
    font-weight: 700;
    padding: 6px 16px;
    display: inline-block;
    vertical-align: middle;
    margin: 0 4px 4px 0;
    cursor: pointer;
`;

export const Btn = styled.button`
    ${btnStyling};
    ${({color}) => {
        if (color)
            return `
        background: var(--${color});
        border: 1px solid var(--${color});
        color: white;
        &:hover{
            background: var(--${color}-dark);
            border: 1px solid var(--${color}-dark);
            transition: 0.6s;
        }
        `;
        else return '';
    }}

    ${({link, color}) => {
        if (link)
            return `
        color: var(--${color});
        border: none;
        background: none;
        &:hover{
            color: var(--${color}-dark);
            border: none;
            background: none;
            transition: 0.6s;
        } 
        `;
        else return '';
    }}
    ${({btnIcon}) => {
        if (btnIcon)
            return `
            width: 40px;
            height: 40px; 
            position: relative;
        ${IconContainer} {
            position: absolute;
            top: 50%;
            left : 50%;
            transform: translate(-50%, -50%);
        }
        `;
        else return '';
    }}



  ${({disabled}) => {
        if (disabled)
            return `
        background: gray!important;
        border: gray!important;
        color: white;
        cursor: not-allowed;
        transform: none;
        opacity: 0.4;
        `;
        else return '';
    }}
`;

export const CancelBtn = styled.button`
    ${btnStyling};
    ${({color}) => {
        if (color)
            return `
        background: white;
        border: 1px solid #dddddd;
        color: var(--primary);
        &:hover{
            color: var(--primary-dark);
            border: 1px solid #dddddd;
            background: var(--primary-light);;
            transition: 0.6s;
        }
        `;
        else return '';
    }}
`;
