import {createGlobalStyle} from 'styled-components';
import MontserratBold from '../../assets/Montserrat/Montserrat-Bold.ttf';
import MontserratSemiBold from '../../assets/Montserrat/Montserrat-SemiBold.ttf';
import MontserratMedium from '../../assets/Montserrat/Montserrat-Medium.ttf';
import MontserratRegular from '../../assets/Montserrat/Montserrat-Regular.ttf';

const Fonts = createGlobalStyle`
    @font-face {
        font-family: 'Montserrat';
        src: url(${MontserratBold});
        font-weight: 900;
    }
    @font-face {
        font-family: 'Montserrat';
        src: url(${MontserratSemiBold});
        font-weight: 700;
    }
    @font-face {
        font-family: 'Montserrat';
        src: url(${MontserratMedium});
        font-weight: 400;
    }
    @font-face {
        font-family: 'Montserrat';
        src: url(${MontserratRegular});
        font-weight: 100;
    }
`;

export default Fonts;
