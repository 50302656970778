import styled from 'styled-components';
import IconContainer, {Icon} from '../icon/Icon';
import React from 'react';

const Tab = styled.div`
    display: flex;
    font-size: 11px;
    ${({stacked}) => {
        if (!stacked) return '';
        else
            return `
            display: block;
        `;
    }};
`;

const TabOptionContainer = styled.div`
    color: black;
    cursor: pointer;
    display: flex;
    aling-items: center;
    width: calc(100% - 28px);
    position: relative;
    margin-bottom: 8px;
    &:hover {
        color: var(--primary-dark);
    }
    ${IconContainer} {
        position: absolute;
        right: -20px;
        top: 50%;
        transform: translateY(-50%);
        svg {
            width: 14px;
        }
    }
    ${({active}) => {
        if (!active) return '';
        else
            return `
            color: var(--primary);
            span svg{
              color: var(--primary);
            }
        `;
    }}
`;

const TabOption = ({children, active, ...props}) => (
    <TabOptionContainer active={active} {...props}>
        {children}
        {active && <Icon icon="arrowForward" />}
    </TabOptionContainer>
);

export {Tab, TabOption, TabOptionContainer};
Tab.Option = TabOption;
