import React from 'react';
import styled from 'styled-components';
import {Formik, Form, Field} from 'formik';
import AutoSave from '../../../util/AutoSave.js';
import {formElementSetup, insideFormElementSetup} from '../../form/form-setup/formElementSetup.js';
import {withTranslation} from 'react-i18next';

function SearchBar({onSearch, value, t}) {
    return (
        <>
            <Formik onSubmit={(values) => onSearch(values.search)} initialValues={{search: value}}>
                <Form>
                    <AutoSave />
                    <Wrapper>
                        <Field autoFocus type="text" name="search" placeholder={t('general.Search')} />
                    </Wrapper>
                </Form>
            </Formik>
        </>
    );
}

export default withTranslation()(SearchBar);

export const Wrapper = styled.div`
    ${formElementSetup}
    input {
        ${insideFormElementSetup}
        outline: none!important;
    }
`;
