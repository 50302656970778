import React from 'react';
import styled from 'styled-components';
import AuthContext, {AuthProvider} from './modules/account/AuthContext';
import {HashRouter, Routes, Route, Navigate} from 'react-router-dom';
import {NotificationContainer} from 'react-notifications';

import Login from './modules/account/Login.jsx';
import ForgotPassword from './modules/account/ForgotPassword.jsx';
import ForgotPasswordDone from './modules/account/ForgotPasswordDone.jsx';
import ResetPassword from './modules/account/ResetPassword.jsx';
import ResetPasswordDone from './modules/account/ResetPasswordDone.jsx';
import ColorVariables from './shared/variables/ColorVariables.js';
import SpaceVariables from './shared/variables/SpaceVariables.js';
import UserOverview from './modules/users/UserOverview';
import VariableOverview from './modules/variables/VariableOverview';
import VehicleOverview from './modules/vehicles/VehicleOverview';
import VehicleOvertimes from './modules/overtimes/VehicleOvertimes';
import {MainContent} from './shared/grid/main-content/MainContent';
import ActivateAccountDone from './modules/account/ActivateAccountDone';
import ActivateAccount from './modules/account/ActivateAccount';

import NavBar from './NavBar';
import {withTranslation} from 'react-i18next';
import './util/i18n.js';
import CseTool from './modules/csetool/CseTool.jsx';
import Stilstand from './modules/stilstand/Stilstand';

const StylingVar = styled.div`
    ${ColorVariables}
    ${SpaceVariables}
`;

function App({t}) {
    return (
        <AuthProvider>
            <AuthContext.Consumer>
                {(auth) => (
                    <StylingVar>
                        <header>
                            <HashRouter>
                                {!auth.loaded ? (
                                    <div>{t('general.Loading')}</div>
                                ) : auth.isLoggedIn ? (
                                    <>
                                        <NavBar auth={auth} />
                                        <MainContent>
                                            <Routes>
                                                {auth.user.role === 'Admin' && (
                                                    <>
                                                        <Route exact path="/csetool" element={<CseTool />} />
                                                        <Route exact path="/users" element={<UserOverview />} />
                                                        <Route exact path="/vehicles" element={<VehicleOverview />} />
                                                        <Route exact path="/variables" element={<VariableOverview />} />
                                                    </>
                                                )}
                                                {(auth.user.role === 'Admin' || auth.user.role === 'User') && (
                                                    <>
                                                        <Route
                                                            exact
                                                            path="/weekendhours"
                                                            element={<VehicleOvertimes />}
                                                        />
                                                    </>
                                                )}
                                                {(auth.user.role === 'Admin' || auth.user.role === 'User') && (
                                                    <>
                                                        <Route exact path="/stationarytool" element={<Stilstand />} />
                                                    </>
                                                )}
                                                {auth.user.role === 'Admin' && (
                                                    <Route path="/*" element={<Navigate replace to="/csetool" />} />
                                                )}
                                                {auth.user.role === 'User' && (
                                                    <Route
                                                        path="/*"
                                                        element={<Navigate replace to="/weekendhours" />}
                                                    />
                                                )}
                                            </Routes>
                                        </MainContent>
                                    </>
                                ) : (
                                    <Routes>
                                        <Route exact path="/login" element={<Login />} />
                                        <Route exact path="/wachtwoord-vergeten" element={<ForgotPassword />} />
                                        <Route exact path="/email-verstuurd" element={<ForgotPasswordDone />} />
                                        <Route
                                            exact
                                            path="/wachtwoord-resetten/:userId/:code"
                                            element={<ResetPassword />}
                                        />
                                        <Route exact path="/wachtwoord-ingesteld" element={<ResetPasswordDone />} />
                                        <Route
                                            exact
                                            path="/activate-account/:userId/:code"
                                            element={<ActivateAccount />}
                                        />
                                        <Route exact path="/activate-account-done" element={<ActivateAccountDone />} />
                                        <Route path="/*" element={<Navigate replace to="/login" />} />
                                    </Routes>
                                )}
                            </HashRouter>
                        </header>

                        <NotificationContainer />
                    </StylingVar>
                )}
            </AuthContext.Consumer>
        </AuthProvider>
    );
}

export default withTranslation()(App);
