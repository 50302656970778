import styled from 'styled-components';
import React from 'react';
import {CardBody, CardContainer} from '../../elements/card/Card';
import IconContainer from '../../elements/icon/Icon';

export const MessageContainer = styled(CardContainer)`
    border-radius: 3px;
    margin: 0;
    ${({color}) => {
        if (color)
            return `
            background: var(--${color}-light);
            border-color: var(--${color});
            color: var(--${color});
        `;
        else return '';
    }}
    ${CardBody} {
        padding-top: 10px;
        padding-bottom: 10px;
        display: inline-flex;
        align-items: center;
        ${IconContainer} {
            margin-right: 8px;
        }
    }
`;

export const MessageCard = ({children, color}) => (
    <MessageContainer color={color}>
        <CardBody>{children}</CardBody>
    </MessageContainer>
);
