import axios from 'axios';

export function downloadKilometerstaten(file) {
    var formData = new FormData();
    formData.append('file', file);

    return axios({
        method: 'post',
        responseType: 'arraybuffer',
        url: '/api/comparison/kilometerstaten',
        data: formData,
    });
}

export function downloadWeekstaten(file) {
    var formData = new FormData();
    formData.append('file', file);

    return axios({
        method: 'post',
        responseType: 'arraybuffer',
        url: '/api/comparison/weekstaten',
        data: formData,
    });
}

export function downloadResultsOverview(file) {
    var formData = new FormData();
    formData.append('file', file);

    return axios({
        method: 'post',
        responseType: 'arraybuffer',
        url: '/api/comparison/results-overview',
        data: formData,
    });
}

export function downloadSummaryPerWeek(week) {
    var formData = new FormData();
    formData.append('week', week);

    return axios({
        method: 'post',
        responseType: 'arraybuffer',
        url: '/api/comparison/summary-week',
        data: formData,
    });
}

export function downloadVehicleRevenues(file) {
    var formData = new FormData();
    formData.append('file', file);

    return axios({
        method: 'post',
        responseType: 'arraybuffer',
        url: '/api/comparison/vehicle-revenues',
        data: formData,
    });
}

export function compare(kilometerstatenFile, rittenlijstenFile) {
    var formData = new FormData();
    formData.append('kilometerstatenFile', kilometerstatenFile);
    formData.append('rittenlijstenFile', rittenlijstenFile);

    return axios({
        method: 'post',
        responseType: 'json',
        url: '/api/comparison/compare',
        data: formData,
    });
}

export function retrieveMendriXData() {
    return axios.get('api/comparison/mendrix-start');
}

export function loadMendriXStatus() {
    return axios.get('api/comparison/mendrix-status');
}

export function terminateMendriXRetrieval() {
    return axios.get('api/comparison/mendrix-terminate');
}
