import {Formik, getIn} from 'formik';
import {FormInput} from '../../shared/form/form-control/FormInput.js';
import React from 'react';
import Table from '../../shared/elements/table/Table';
import {MainContent} from '../../shared/grid/main-content/MainContent';
import useVariables from './VariableState.js';
import AutoSave from '../../util/AutoSave.js';
import _ from 'lodash';
import {NotificationManager} from 'react-notifications';
import {withTranslation} from 'react-i18next';

function VariableOverview({t}) {
    const {loading, variables, variableUpdate, reload} = useVariables(t);

    const submitForm = (values) => {
        _.map(
            _.filter(values.variables, (variable, index) => !_.isEqual(variable, variables[index])),
            (variable) =>
                variableUpdate(variable).then(() => {
                    NotificationManager.success(t('general.ChangesSaved'));
                    reload();
                }),
        );
    };

    const validate = (values) => {
        let errors = {};

        _.forEach(values.variables, (variable, index) => {
            if (variable.type === 'Currency') {
                if (!variable.value.match(/^(0|[1-9]\d*)(,\d\d\d?)?$/)) {
                    _.set(errors, `variables[${index}.value]`, t('errors.InvalidFormat'));
                }
            }
        });

        return errors;
    };

    return (
        <div>
            {loading == 0 ? (
                <>
                    <MainContent.Body title={t('general.VariableOverview')} />
                    <MainContent.Body>
                        <Table>
                            <thead>
                                <Table.Row>
                                    <Table.Header>{t('variables.Variable')}</Table.Header>
                                    <Table.Header>{t('variables.Value')}</Table.Header>
                                    <Table.Header>{t('variables.Type')}</Table.Header>
                                </Table.Row>
                            </thead>
                            <tbody>
                                <Formik
                                    onSubmit={submitForm}
                                    initialValues={{
                                        variables: variables,
                                    }}
                                    validateOnChange={false}
                                    validateOnBlur={false}
                                    validate={validate}
                                >
                                    {({values, errors}) => (
                                        <>
                                            <AutoSave />
                                            {values.variables?.length === 0 && (
                                                <Table.Row>
                                                    <Table.Data colSpan="2">
                                                        {t('variables.NoVariablesFound')}
                                                    </Table.Data>
                                                </Table.Row>
                                            )}

                                            {values.variables?.length > 0 &&
                                                values.variables.map((variable, index) => (
                                                    <Table.Row key={index}>
                                                        <>
                                                            <Table.Data>
                                                                {t(`variables.names.${variable.name}`)}
                                                            </Table.Data>
                                                            <Table.Data>
                                                                <FormInput
                                                                    size="sm"
                                                                    prefixIcon="euro"
                                                                    name={`variables[${index}].value`}
                                                                    placeholder={variable.value}
                                                                    borderError={getIn(
                                                                        errors,
                                                                        `variables[${index}].value`,
                                                                    )}
                                                                />
                                                            </Table.Data>
                                                            <Table.Data>
                                                                {t(`variables.types.${variable.type}`) || variable.type}
                                                            </Table.Data>
                                                        </>
                                                    </Table.Row>
                                                ))}
                                        </>
                                    )}
                                </Formik>
                            </tbody>
                        </Table>
                    </MainContent.Body>
                </>
            ) : (
                <div>{t('general.Loading')}</div>
            )}
        </div>
    );
}

export default withTranslation()(VariableOverview);
