import React from 'react';
import styled from 'styled-components';
import {btnStyling} from '../../elements/btn/Btn';

export const FormFile = ({label, fileName, ...props}) => (
    <FormFileContainer>
        {label && (
            <>
                <label htmlFor={label}>{label}</label>
                <input type="file" id={label} {...props} />
                {fileName && <FormFileUploadTxt>{fileName && (' (' + fileName + ')' || '')}</FormFileUploadTxt>}
            </>
        )}
    </FormFileContainer>
);

export const FormFileContainer = styled.div`
    label {
        ${btnStyling};
        color: white;
        background: var(--primary);
        border: 1px-solid var(--primary);
        &:hover {
            background: var(--primary-dark);
            border: 1px-solid var(--primary-dark);
        }
    }
    input {
        visibility: hidden;
    }
`;

const FormFileUploadTxt = styled.div`
    color: var(--default-sub-txt);
    font-size: 12px;
    margin-top: var(--space-xsm);
`;
