import {t} from 'i18next';
import * as React from 'react';
import Table from '../../../shared/elements/table/TableFeature';

function VehicleJourneyEntries({journeys, totalCost}) {
    return (
        <>
            {journeys
                .sort((a, b) => a.firstTaskTimestamp < b.firstTaskTimestamp)
                .map((row, index) => {
                    return (
                        <Table.Row key={index}>
                            <Table.Data>{row.orderKey}</Table.Data>
                            <Table.Data>{row.relationName}</Table.Data>
                            <Table.Data>{row.firstTaskPlace}</Table.Data>
                            <Table.Data>{row.firstTaskPostalCode}</Table.Data>
                            <Table.Data>{row.firstTaskTimestamp}</Table.Data>
                            <Table.Data>{row.lastTaskTimestamp}</Table.Data>
                            <Table.Data>{row.lastTaskPlace}</Table.Data>
                            <Table.Data>{row.lastTaskPostalCode}</Table.Data>
                            <Table.Data>{row.numberOfTasks}</Table.Data>
                            <Table.Data>
                                €{' '}
                                {row.purchaseCost.toLocaleString('nl', {
                                    useGrouping: false,
                                    minimumFractionDigits: 2,
                                })}
                            </Table.Data>
                            <Table.Data>
                                €{' '}
                                {row.purchaseCostExclBTW.toLocaleString('nl', {
                                    useGrouping: false,
                                    minimumFractionDigits: 2,
                                })}
                            </Table.Data>
                            <Table.Data>
                                {row.purchaseCostExclBTWLeft === 0
                                    ? ''
                                    : '€' +
                                      row.purchaseCostExclBTWLeft.toLocaleString('nl', {
                                          useGrouping: false,
                                          minimumFractionDigits: 2,
                                      })}
                            </Table.Data>
                        </Table.Row>
                    );
                })}
            <Table.Row>
                <Table.Data></Table.Data>
                <Table.Data></Table.Data>
                <Table.Data></Table.Data>
                <Table.Data></Table.Data>
                <Table.Data></Table.Data>
                <Table.Data></Table.Data>
                <Table.Data></Table.Data>
                <Table.Data></Table.Data>
                <Table.Data></Table.Data>
                <Table.Data></Table.Data>
                <Table.Data bold>
                    <b>{t('vehicleRevenue.Total')}:</b>
                </Table.Data>
                <Table.Data bold>
                    <u>
                        <b>
                            €{' '}
                            {totalCost &&
                                totalCost.toLocaleString('nl', {
                                    useGrouping: false,
                                    minimumFractionDigits: 2,
                                })}
                        </b>
                    </u>
                </Table.Data>
            </Table.Row>
        </>
    );
}

export default VehicleJourneyEntries;
