import _ from 'lodash';

function errorHandler(e, doAlert, t) {
    if (_.isArrayBuffer(e)) {
        try {
            e = JSON.parse(new TextDecoder().decode(e));
        } catch (error) {
            // Apparently this is not a json object and thus shouldn't be shown to end user.
            e = t('errors.SomethingWrong');
        }
    }

    if (_.isString(e) || _.isString(e?.error)) {
        const tryFindTranslation = t(`errors.${e.error ?? e}`);
        const errorString = tryFindTranslation.startsWith('errors.') ? e.error ?? e : t(`errors.${e.error ?? e}`);
        if (doAlert) alert(errorString);
        return errorString;
    }

    if (_.isArray(e)) {
        return errorHandlerArray(e, doAlert, t);
    }

    let errors;
    if (e.response?.data.errors) {
        if (e.response.data.errors.Password) {
            errors = e.response.data.errors.Password;
        } else {
            errors = [];
        }
    } else {
        errors = e.response.data.split(',');
        errors = errors.map((e) => t(`errors.${e}`));
    }

    const errorString = errors?.join('\n') ?? t('errors.SomethingWrong');

    if (doAlert) alert(errorString);
    return errorString;
}

function errorHandlerArray(errors, doAlert, t) {
    let translatedErrors = [];

    translatedErrors = errors.map((e) => t(`errors.${e}`));

    const errorString = translatedErrors.join('\n') || t('errors.SomethingWrong');

    if (doAlert) alert(errorString);
    return errorString;
}

function errorHandlerPasswordRequires(e, doAlert, t) {
    let passwordRequiresErrorsString;
    if (_.isArray(e.errors?.Password)) passwordRequiresErrorsString = e.errors.Password.join(',');
    else {
        e = e.error;

        let passwordRequiresErrors = e.split(',').filter((e) => e.startsWith('PasswordRequires'));
        passwordRequiresErrorsString = t('errors.PasswordRequires');
        for (const [i, e] of passwordRequiresErrors.entries()) {
            if (i === passwordRequiresErrors.length - 1) {
                if (passwordRequiresErrors.length === 1) {
                    passwordRequiresErrorsString = passwordRequiresErrorsString.replace('<>', t(`errors.${e}`));
                } else {
                    passwordRequiresErrorsString = passwordRequiresErrorsString.replace(
                        '<>',
                        t('general.And').toLowerCase() + ' ' + t(`errors.${e}`),
                    );
                }
            } else if (i === 0) {
                passwordRequiresErrorsString = passwordRequiresErrorsString.replace('<>', t(`errors.${e}`) + ' <>');
            } else {
                passwordRequiresErrorsString = passwordRequiresErrorsString.replace(
                    ' <>',
                    ', ' + t(`errors.${e}`) + ' <>',
                );
            }
        }
    }

    if (doAlert) alert(passwordRequiresErrorsString);
    return passwordRequiresErrorsString;
}

export default errorHandler;
export {errorHandlerPasswordRequires};
