import styled from 'styled-components';
import Table from './Table.js';
import {RouterLink} from '../btn/RouterLink.js';
import {Btn} from '../btn/Btn';
import {FormInputContainer} from '../../form/form-control/FormInput.js';

export const tableColSetup = ({background, color, fontSize, fontWeight, borderRadius, paddingVert, paddingHorz}) => `
    background: ${background || 'white'};
    border: none;
    color: ${color || 'var(--default-txt)'};
    font-size: ${fontSize || '10px'};
    font-weight: ${fontWeight || '700'};
    padding: ${paddingVert || '6px'} ${paddingHorz || '12px'};
    position: relative;
    text-align: left;
    
    &:last-of-type {
        border-radius: 0 ${borderRadius || '2px'} ${borderRadius || '2px'} 0;
        border-right: 1px solid transparent;
    }
    &:first-of-type {
        border-radius: ${borderRadius || '2px'} 0 0 ${borderRadius || '2px'};
        border-left: 1px solid transparent;
    }
`;

const TableRow = styled.tr`
    border: none;
    box-shadow: 1px 1px 6px 1px rgba(38, 38, 38, 0.056);

    ${({warning}) => {
        if (!warning) return 'width: 100%;';
        else
            return `
        box-shadow: 1px 1px 6px 1px rgba(242, 149, 149,  0.08);
        width: 100%;
            td{
                background: #fff4f1;
                color: var(--danger);
            }
        `;
    }}
    ${({active}) => {
        if (!active) return;
        else
            return `
            td {
                background: #F5F8FF;
            }
            outline-style: solid;
            outline-color: #A2BBFB;
            outline-width: 1px;
            border-radius: 2px;
        `;
    }}
`;

const StripedTable = styled(Table)`
    tbody tr:nth-child(even) {
        box-shadow: 0px 0px 3px 0px #2626264f;
        td {
            background-color: #edeef0;
            ${FormInputContainer} {
                border: 1px solid #d1d2db;
            }
        }
    }
`;

const TableRowHeader = styled.th`
    z-index: 1;
    cursor: pointer;
    ${tableColSetup({
        color: 'var(--default-sub-txt)',
        fontSize: '10px',
        fontWeight: '400',
        paddingVert: '4px',
    })};

    ${({smallrow}) => {
        if (!smallrow) return '';
        else
            return `
            width: 120px;
            `;
    }}
`;
const TableRowData = styled.td`
    min-width: 40px;
    ${tableColSetup}
    width: min-content;
    vertical-align: middle;
    font-weight: 500;
    ${RouterLink} {
        padding: 0;
    }
    ${({iconRow}) => {
        if (!iconRow) return '';
        else
            return `
                padding-left: 0;
                width: 20px;
                min-width: 20px;
                ${Btn}, ${RouterLink} {
                    padding: 0;
                    svg{
                        width: 13px;
                    }
                }
            `;
    }}

    ${({bold}) => {
        if (!bold) return '';
        else
            return `
            font-weight: 900;
        `;
    }} 
    > div {
        margin-bottom: 0;
    }
    strong {
        font-weight: 900;
        font-size: 10px;
    }
`;

const TableBadge = styled.span`
    font-size: 12px;
    font-weight: 900;
    background: var(--primary);
    padding: 2px 5px;
    color: white;
    border-radius: 2px;
`;

const TableFormField = styled.div`
    display: flex;
    align-items: center;
    > * {
        margin: 0 4px 0 0;
        display: block;
    }
`;

export {TableRow, StripedTable, TableRowHeader, TableRowData, TableFormField, TableBadge};
Table.Row = TableRow;
Table.Header = TableRowHeader;
Table.Data = TableRowData;
Table.FormField = TableFormField;
Table.Badge = TableBadge;
export default Table;
