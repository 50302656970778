import styled from 'styled-components';

export const MainContent = styled.div`
    padding: calc(var(--space-lg) + 80px) var(--space-lg) var(--space-lg);
`;

export const ContentBodyContainer = styled.div`
    &:not(:last-of-type) {
        margin-bottom: var(--space-lg);
        width: calc(100% - (var(--sidebar-width) + var(--space-lg)));
        form {
            margin-right: 0;
        }
    }
`;
