import styled from 'styled-components';
import {scrollSetup} from '../../custom-features/scroll/scroll';
import {ContentBodyContainer} from './MainContent';

export const MainContentSidebar = styled.div`
    margin-right: calc(0px - var(--space-lg));
    --sidebar-width: 480px;

    ${ContentBodyContainer} {
        width: calc(100% - var(--sidebar-width));
        padding-right: var(--space-lg);
    }
`;

export const Sidebar = styled.div`
    background: white;
    box-shadow: 0 0 3px 0 #0a1b2d82;
    height: 100%;
    position: fixed;
    top: 80px;
    right: 0;
    width: var(--sidebar-width);
    overflow-y: scroll;
    overflow-x: hidden;
    padding-bottom: 80px;
    ${scrollSetup}
`;

export const SidebarBody = styled.div`
    padding: var(--space-lg);
`;

export const SidebarHeader = styled.div`
    padding: var(--space-lg);
    color: var(--alpha);
    font-weight: 700;
    border-bottom: 1px solid #939aaa3b;
`;

Sidebar.Body = SidebarBody;
Sidebar.Header = SidebarHeader;
