import styled from 'styled-components';
import {btnStyling} from './Btn';

export const LoginBtn = styled.button`
    ${btnStyling};
    width: 100%;
    padding: 14px;
    border-radius: 6px;
    background: var(--delta);
    border: 1px solid var(--delta);
    color: white;
    transition: 0.6s;
    &:hover {
        background: var(--delta-dark);
        border: 1px solid var(--delta-dark);
    }
`;
