import styled from 'styled-components';
import React from 'react';
import {Field, ErrorMessage} from 'formik';
import {FormLabel} from '../form-features/FormLabel';
import {ValidationErrorTxt} from '../form-features/ValidationErrorTxt';

export const FormInput = styled.div`
    height: 40px;
    width: 100%;
    border-radius: 2px;
    border: 1px solid #eaecf0;
    position: relative;
    input {
        -webkit-appearance: none;
        -moz-appearance: none;
        background: none;
        border: none;
        overflow: hidden;
        width: 100%;
        height: 100%;
        padding: 0 20px;
        color: white;
    }
`;

export const FormLoginContainer = styled.div`
    margin-bottom: 28px;
    label {
        color: white;
    }
`;

export const FormLogin = ({label, name, ...props}) => (
    <FormLoginContainer>
        {label && <FormLabel>{label}</FormLabel>}
        <FormInput>
            <Field {...props} label={name} name={name} />
        </FormInput>
        <div>
            <ErrorMessage name={name}>{(msg) => <ValidationErrorTxt>{msg}</ValidationErrorTxt>}</ErrorMessage>
        </div>
    </FormLoginContainer>
);
