import React from 'react';
import styled from 'styled-components';
import {FormGroup} from '../form-features/FormGroup';
import {formElementSetup, insideFormElementSetup} from '../form-setup/formElementSetup';
import IconContainer, {Icon} from '../../elements/icon/Icon';
import {FormLabel} from '../form-features/FormLabel';

export const FormSelectContainer = styled.div`
    ${formElementSetup}
    select {
        ${insideFormElementSetup}
        outline: none!important;
    }
    ${IconContainer} {
        position: absolute;
        right: 12px;
        top: 50%;
        transform: translateY(-50%);
        pointer-events: none;
    }
`;

const Wrapper = styled(FormGroup)``;
export const FormSelect = ({children, value, onChange, label, defaultValue}) => {
    return (
        <Wrapper>
            {label && <FormLabel>{label}</FormLabel>}
            <FormSelectContainer>
                <Icon icon="ArrowDropDown" />
                <select defaultValue={defaultValue} value={value} onChange={onChange}>
                    {children}
                </select>
            </FormSelectContainer>
        </Wrapper>
    );
};
