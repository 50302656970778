import styled from 'styled-components';
import {btnStyling} from './Btn';

export const RouterLink = styled.a`
    ${btnStyling};
    ${({color}) => {
        if (color)
            return `
        background: var(--${color});
        border: 1px solid var(--${color});
        color: white;
        &:hover{
            background: var(--${color}-dark);
            border: 1px solid var(--${color}-dark);
            transition: 0.6s;
        }
        `;
        else return '';
    }}

    ${({link, color}) => {
        if (link)
            return `
        color: var(--${color});
        border: none;
        background: none;
        &:hover{
            color: var(--${color}-dark);
            border: none;
            background: none;
            transition: 0.6s;
        } 
        `;
        else return '';
    }}



  ${({disabled}) => {
        if (disabled)
            return `
        background: gray!important;
        border: gray!important;
        color: white;
        cursor: not-allowed;
        transform: none;
        opacity: 0.4;
        `;
        else return '';
    }}
`;
