import React from 'react';
import ReactDOM from 'react-dom';
import App from './App';
import Fonts from './shared/font/Fonts.js';
import BodyContainer from './shared/grid/BodyContainer.js';

ReactDOM.render(
    <React.StrictMode>
        <Fonts />
        <BodyContainer />
        <App />
    </React.StrictMode>,
    document.getElementById('root'),
);
