import React, {useContext} from 'react';
import LoginGrid from '../../shared/grid/gird/LoginGrid';
import {LoginBox} from '../../shared/grid/gird/LoginFeatures';
import {FormLogin} from '../../shared/form/form-control/FormLogin';
import {LoginBtn} from '../../shared/elements/btn/LoginBtn';

import {useNavigate} from 'react-router-dom';
import AuthContext from './AuthContext';
import * as Yup from 'yup';
import {Formik, Form} from 'formik';
import errorHandler from '../../util/errorHandler';
import {withTranslation} from 'react-i18next';

function ForgotPassword({t}) {
    const auth = useContext(AuthContext);
    const navigate = useNavigate();

    const handleSubmit = (values, {setSubmitting, setErrors}) => {
        auth.forgotPassword(values.email)
            .then(() => {
                setSubmitting(false);
                navigate('/email-verstuurd');
            })
            .catch((e) => {
                setSubmitting(false);
                setErrors({email: errorHandler(e.response.data, false, t)});
            });
    };

    const validationSchema = Yup.object().shape({
        email: Yup.string().email(t('errors.InvalidEmail')).required(t('errors.RequiredField')),
    });

    return (
        <LoginGrid>
            <LoginBox.Header>
                <h1>{t('login.forgotPassword')}</h1>
                <h2>{t('login.forgotPasswordText')} </h2>
            </LoginBox.Header>
            <LoginBox.Body>
                <Formik initialValues={{email: ''}} validationSchema={validationSchema} onSubmit={handleSubmit}>
                    {({isSubmitting}) => (
                        <Form>
                            <FormLogin type="text" label={t('login.email')} name="email" />
                            <LoginBtn type="submit" disabled={isSubmitting}>
                                {t('login.send')}
                            </LoginBtn>
                        </Form>
                    )}
                </Formik>
            </LoginBox.Body>
        </LoginGrid>
    );
}

export default withTranslation()(ForgotPassword);
