import styled from 'styled-components';

export const StackedSpacer = styled.div`
    > *:not(:last-child) {
        display: block;
        ${({space}) => {
            if (space) {
                return ` margin-bottom: var(--space-${space})!important`;
            } else {
                return null;
            }
        }}
    }
`;
