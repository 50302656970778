const ColorVariables = () => `
    --primary:                      #699BE3;
    --primary-dark:                 #5A8DD7;
    --primary-light:                #e8f1ff;
    --success:                      #96D08B;
    --success-dark:                 #86C47A;
    --danger:                       #E97579;
    --danger-dark:                  #d34d51;
    --danger-light:                 #fff5f5;
    --warning:                      #F6C143;
    --default-sub-txt:              #939AAA;
    --default-txt:                  #2c2c2c;


    // ---- Brand color ----
    // ---------------------
    --alpha: #162e60;
    --delta:  #fdc921;
    --delta-dark: #e8b305;

    --default: green;
    --default-dark: yellow;




    --form-control-border-col: #EAEBF2;
    --form-control-background-col: #F7F7FA;
    --form-control-txt-col: #1F212A;

    --list-rol-width: 200px;
`;

export default ColorVariables;
