import React, {useContext} from 'react';
import LoginGrid from '../../shared/grid/gird/LoginGrid';
import {LoginBox} from '../../shared/grid/gird/LoginFeatures';
import {FormLogin} from '../../shared/form/form-control/FormLogin';
import {LoginBtn} from '../../shared/elements/btn/LoginBtn';

import {Link, useParams, useNavigate} from 'react-router-dom';

import AuthContext from './AuthContext';
import * as Yup from 'yup';
import {Formik, Form} from 'formik';

import styled from 'styled-components';
import errorHandler from '../../util/errorHandler';
import {withTranslation} from 'react-i18next';

function ResetPassword({t}) {
    const {userId, code} = useParams();
    const auth = useContext(AuthContext);
    const navigate = useNavigate();

    const handleSubmit = (values, {setSubmitting, setErrors}) => {
        auth.resetPassword({...values, userId, code})
            .then(() => {
                setSubmitting(false);
                navigate('/wachtwoord-ingesteld');
            })
            .catch((e) => {
                setSubmitting(false);
                setErrors({password: errorHandler(e.response.data, false, t)});
            });
    };

    const validationSchema = Yup.object().shape({
        password: Yup.string().required(t('errors.RequiredField')),
        passwordConfirm: Yup.string().oneOf([Yup.ref('password'), null], t('errors.NonidenticalPassword')),
    });

    return (
        <LoginGrid>
            <LoginBox.Header>
                <h1>{t('login.passwordReset')} </h1>
                <h2>{t('login.passwordResetText')}</h2>
            </LoginBox.Header>
            <LoginBox.Body>
                <Formik
                    initialValues={{password: '', passwordConfirm: ''}}
                    validationSchema={validationSchema}
                    onSubmit={handleSubmit}
                >
                    {({isSubmitting}) => (
                        <Form>
                            <FormLogin type="password" label={t('login.password')} name="password" />
                            <FormLogin type="password" label={t('login.passwordConfirm')} name="passwordConfirm" />
                            <LoginSchermButton to="/login">{t('login.goBackToLogin')}</LoginSchermButton>
                            <LoginBtn type="submit" disabled={isSubmitting}>
                                {t('login.submit')}
                            </LoginBtn>
                        </Form>
                    )}
                </Formik>
            </LoginBox.Body>
        </LoginGrid>
    );
}
export default withTranslation()(ResetPassword);

const LoginSchermButton = styled(Link)`
    color: white;
    transition: 0.6s;
    font-size: 14px;
    margin-top: var(--space-md);
    text-decoration: none;
    text-align: center;
    width: 100%;
    display: block;
    &:hover {
        color: var(--delta);
    }
`;
