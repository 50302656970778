import axios from 'axios';

export function login(userName, password) {
    return axios.post('/api/account/login', {
        userName: userName,
        password: password,
    });
}

export function forgotPassword(email) {
    return axios.post('/api/account/forgot-password', {
        email: email,
    });
}

export function logout() {
    return axios.post('/api/account/logout');
}

export function resendActivation(userId) {
    return axios.get(`/api/account/resend-activation-mail/${userId}`);
}

export function resetPassword({userId, code, password, passwordConfirm}) {
    return axios.post('/api/account/reset-password', {
        userId: userId,
        code: code,
        password: password,
        passwordConfirm: passwordConfirm,
    });
}

export function activateAccount({userId, code, password, passwordConfirm}) {
    return axios.post('/api/account/activate', {
        userId: userId,
        code: code,
        password: password,
        passwordConfirm: passwordConfirm,
    });
}
