import axios from 'axios';

export function getVehicles(params) {
    return axios.get('/api/vehicles', {params: params});
}

export function getVehicle(licensePlate) {
    return axios.get(`/api/vehicles/${licensePlate}`);
}

export function createVehicle(vehicle) {
    return axios.post('/api/vehicles', vehicle);
}

export function updateVehicle(vehicle) {
    return axios.put(`/api/vehicles/${vehicle.licensePlate}`, vehicle);
}

export function deleteVehicle(licensePlate) {
    return axios.delete(`/api/vehicles/${licensePlate}`);
}
